import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Login from '../modules/user/pages/login/index';
import Register from '../modules/user/pages/register/index';
import { ROUTES } from './types';
import Dashboard from '../modules/dashboard/pages/dashboard';
import ProjectList from '../modules/project/pages/project-list';
import ProjectDetails from '../modules/project/pages/project-details';
import Profile from '../modules/user/pages/profile';
import MyTasks from '../modules/task/pages/my-task';
import TaskDetails from '../modules/task/pages/task-details';
import UserList from '../modules/admin/pages/user';
import ProjectAll from '../modules/admin/pages/project';
import AppLayout from '../layouts/AppLayout';

const AppRouter = (): JSX.Element =>
{

  const { loading, isAuthenticated } = useAppSelector(state => ({
    loading         : state.user.loading,
    isAuthenticated : state.user.isAuthenticated
  }));
    
  return (
    <Router>
      <Switch>
        {!isAuthenticated && <>
          <Route path={ROUTES.login}>
            <Login />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          {!loading && <Route path={'*'}>
            <Redirect to={ROUTES.login} />
          </Route>}
        </> }
        <AppLayout>
          <>
            <Route exact path={[ROUTES.dashboard, '/']}>
              {!loading && <Dashboard />}
            </Route>
            <Route path={ROUTES.projects}>
              {!loading && <ProjectList /> }
            </Route>
            <Route path={ROUTES.projectDetails}>
              {!loading && <ProjectDetails />}
            </Route>
            <Route path={ROUTES.profile}>
              {!loading && <Profile />}
            </Route>
            <Route path={ROUTES.tasks}>
              {!loading && <MyTasks/>}
            </Route>
            <Route path={ROUTES.taskDetails}>
              {!loading && <TaskDetails />}
            </Route>
            <Route path={ROUTES.users}>
              {!loading && <UserList />}
            </Route>
            <Route path={ROUTES.projectAll}>
              {!loading && <ProjectAll />}
            </Route>
            <Route exact path={ROUTES.login}>
              <Redirect to={'/'} />
            </Route>
          </>
        </AppLayout>
      </Switch>
    </Router>
  );
};

export default AppRouter;