/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ILoginForm, USER_CHECK_LOGIN_LOCAL_STORAGE, USER_CHECK_LOGIN_SERVER, USER_LOGIN_SERVER, USER_LOGOUT_SERVER } from './types';


export const loginServer = (payload: ILoginForm) => ({
  type: USER_LOGIN_SERVER,
  payload
});

export const checkLoginServer = (payload: any) => ({
  type: USER_CHECK_LOGIN_SERVER,
  payload
});

export const checkLoginLocalStorage = () => ({
  type: USER_CHECK_LOGIN_LOCAL_STORAGE
});

export const logoutServer = () => ({
  type: USER_LOGOUT_SERVER,
});