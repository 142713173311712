/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import
{
  Form,
  Input,
  Switch,
} from 'antd';
import ApiUtils from '../../../global/services';
import { useAppDispatch, } from '../../../app/hooks';
import { USER_CREATE, USER_UPDATE } from '../../user/api';
import { disableLoading, enableLoading } from '../../../global/slice';
import { notify } from '../../../global/helpers';
import { ICreateUser } from '../../user/types';
import { IModalUser } from '../types';


const ModalUser = (props: IModalUser): JSX.Element =>
{
  const dispatch = useAppDispatch();

  const defaultValues = props.user ? {
    email      : props.user.email,
    name       : props.user.name || '',
    status     : props.user.status,
    department : props.user.department || '',
    position   : props.user.position || ''
  } : {
    email      : '',
    name       : '',
    status     : true,
    department : '',
    position   : ''
  };
    
  
  const onCreateUser = async (data: ICreateUser) =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({ api: USER_CREATE, method: 'post', data });
    if (response.success)
    {
      notify({ type: 'success', message: 'Successfully created new user' });
      props.onClose?.(true);
    } else
    {
      notify({ type: 'error', message: response.message });
    }
    dispatch(disableLoading());
  };
    
  const onUpdateUser = async (id: string, data: ICreateUser) =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({ api: `${USER_UPDATE}/${id}`, method: 'post', data });
    if (response.success)
    {
      notify({ type: 'success', message: 'User update successful' });
      props.onClose?.(true);
    } else
    {
      notify({ type: 'error', message: response.message });
    }
    dispatch(disableLoading());
  };
    

  const onFinish = (values: ICreateUser) =>
  {
    if (props.user)
    {
      onUpdateUser(props.user.id!, values);
    } else
    {
      onCreateUser(values);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
    
  return (
    <Form<ICreateUser>
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={defaultValues}
    >
      <div className="">
        <h5>{ defaultValues.name ? 'Update User' : 'Create User'}</h5>
        <div className="mb-3">
          <label className="form-label">{'Email'}</label>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input user gmail!' },
              { type    : 'email',
                // pattern : new RegExp('gmail.com', 'g'),
                message : 'Email is not a valid Gmail!' },
            ]}
          >
            <Input
              placeholder={'Enter gmail'}
            />
          </Form.Item>
        </div>
        <div className="mb-3 flex-row-direction">
          <label style={{ marginBottom: 0, marginRight: 10 }} className="form-label">Status</label>
          <Form.Item
            name="status"
            style={{ marginBottom: 0 }}
          >
            <Switch defaultChecked={defaultValues.status} />
          </Form.Item>
        </div>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <Form.Item
            name="name"
          >
            <Input
              placeholder={'Enter name'}
            />
          </Form.Item>
        </div>
        <div className="mb-3">
          <label className="form-label">Position</label>
          <Form.Item
            name="position"
          >
            <Input
              placeholder={'Enter position'}
            />
          </Form.Item>
        </div>
        <div className="mb-3">
          <label className="form-label">Department</label>
          <Form.Item
            name="department"
          >
            <Input
              placeholder={'Enter department'}
            />
          </Form.Item>
        </div>
      </div>
      <div className="modal-footer">
        <Form.Item >
          <button type="submit" className="btn btn-primary">
            {props.user ? 'Update' : 'Create'}
          </button>
        </Form.Item>
        
      </div>
    </Form>
  ); 
};

export default ModalUser;
