/* eslint-disable react/display-name */
import React, { useRef, useState } from 'react';
import { Table, Tag, Popover, Popconfirm, Select, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { notify } from '../../../../../global/helpers';
import ApiUtils from '../../../../../global/services';
import { disableLoading, enableLoading } from '../../../../../global/slice';
import { TASK_DELETE } from '../../../api';
import { ITaskItem } from '../../../types';
import moment from 'moment';
import ModalComponent from '../../../../../components/modal';
import ModalTask from '../../../components/ModalTask';
import { RefModal } from '../../../../../components/modal/types';
import { taskStatus } from '../../../../../global/constants';

const { Option } = Select;
const { Search } = Input;

interface ITaskGrid{
  tasks: ITaskItem[];
  onSetFilter: (e: {keyword?: string, status?: string}) => void;
  onFetchTask: () => void;
  title?: string;
  projectId?: string;
}
const TaskGrid = (props: ITaskGrid): JSX.Element =>
{
  const { tasks, onSetFilter, onFetchTask, title, projectId } = props;

  const columns = [
    {
      title     : 'Name',
      dataIndex : 'name',
      key       : 'name',
      render    : (text: string) => (
        <div className={'task-name'} >
          <strong>
            {text}
          </strong>
        </div>
      ),
    },
    {
      title     : 'Due date',
      dataIndex : 'dueDate',
      key       : 'dueDate',
      render    : (dueDate: string) => (
        <div className={'task-due-date task-center-cell'}>
          {dueDate ? moment(dueDate).calendar() : '_'}
        </div>
      ),
    },
    {
      title     : 'Subtask',
      dataIndex : 'numOfSubtask',
      key       : 'numOfSubtask',
      render    : (subtask: string) => (
        <div className={'task-subtask task-center-cell'}>
          {subtask || '0'}
        </div>
      ),
    },
    {
      title     : 'Status',
      key       : 'status',
      dataIndex : 'status',
      render    : (status: 'TO_DO' | 'DONE' | 'IN_PROGRESS') =>
      {
        let content;
        switch (status) {
          case 'TO_DO':
            content = <div className={'task-status task-center-cell'}>
              <Tag icon={<i className={taskStatus.TO_DO.icon}></i>} color="warning">
                {taskStatus.TO_DO.name}
              </Tag>
            </div>;
            break;
          case 'IN_PROGRESS':
            content = <div className={'task-status task-center-cell'}>
              <Tag icon={<i className={taskStatus.IN_PROGRESS.icon}></i>} color="processing">
                {taskStatus.IN_PROGRESS.name}
              </Tag>
            </div>;
            break;
          default:
            content = <div className={'task-status task-center-cell'}>
              <Tag icon={<i className={taskStatus.DONE.icon}></i>} color="success">
                {taskStatus.DONE.name}
              </Tag>
            </div>;
            break;
        }
          
        return content;
      },
    },
    {
      title     : 'Description',
      key       : 'description',
      dataIndex : 'description',
      render    : (text: string) =>
      {
        if (text && text.length > 40)
        {
          const content = (
            <div className={'task-description-popover'}>
              <span>
                {text}
              </span>  
            </div>
          );
          return (
            <Popover
              placement={'left'}
              content={content}>
              <div className={'task-description'}>
                <span>
                  {'Hover to view description'}
                </span>
              </div>
            </Popover>
          );}
        return (
          <div className={'task-description'}>
            <span>
              {text || '_'}
            </span>
          </div>
        );
      },
    },
    {
      title     : 'Action',
      key       : 'action',
      dataIndex : 'action',
      render    : (text: string, row: ITaskItem) =>
      {
        return (
          <div className="btn-group  task-center-cell">
            <button
              onClick={() => onEditTask(row)}
              type="button" className="btn btn-outline-secondary">
              <i className="icofont-edit text-success" />
            </button>
                
            <Popconfirm
              placement={'topLeft'}
              title="Are you sure to delete this task?"
              onConfirm={() => onDeleteTask(row)}
              okText="Delete"
              cancelText="Cancel"
            >
              <button
                type="button" className="btn btn-outline-secondary deleterow"><i className="icofont-ui-delete text-danger" /></button>
            </Popconfirm>
            <button
              onClick={() => onTaskDetails(row)}
              type="button" className="btn btn-outline-secondary">
              <i className="far fa-eye"></i>
            </button>
          </div>
        );
      }
    }
  ];
    
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => ({
    loading: state.app.loading
  }));
    
  const [currentTask, setCurrentTask] = useState<ITaskItem | undefined>();
  const modalTask = useRef<RefModal>(null);
    
  const onDeleteTask = async (task: ITaskItem) =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({
      api    : `${ TASK_DELETE }/${ task.id }`,
      method : 'delete'
    });
    console.log('response: ', response);
    if (response.success)
    {
      notify({ type: 'success', message: 'Delete task successfully' });  
      dispatch(disableLoading());
      onFetchTask();
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };

  const onEditTask = (task: ITaskItem) =>
  {
    setCurrentTask(task);
    modalTask.current?.show();
  };
    
  const onCloseModal = (refresh: boolean) =>
  {
    if (refresh)
    {
      onFetchTask();
    }
    setCurrentTask(undefined);
    modalTask.current?.hide();
  };
    

  const onTaskDetails = (task: ITaskItem) =>
  {
    history.push(`/task/${task.id}`);
  };

  return (
    <>
      <div className="body d-flex py-lg-3 py-md-2 task-list">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">{title || ''}</h3>
                <div className={'task-filter'}>
                  <Search
                    placeholder="Input search text"
                    className={'search-box'}
                    allowClear
                    onChange={(event) => onSetFilter({ keyword: event.target.value || '' })}
                    style={{ width: 300 }} />
                  <Select<string>
                    allowClear
                    placeholder="Filter status"
                    onChange={(value) => onSetFilter({ status: value || '' })}
                  >
                    <Option key={'TO_DO'} value={'TO_DO'} >{'To do'}</Option>
                    <Option key={'IN_PROGRESS'} value={'IN_PROGRESS'} >{'In progress'}</Option>
                    <Option key={'DONE'} value={'DONE'} >{'Done'}</Option>
                  </Select>
                </div>
                <div className="col-auto d-flex w-sm-100">
                  <button
                    onClick={() => modalTask.current?.show()}
                    type="button" className="btn btn-dark btn-set-task w-sm-100">
                    <i className="icofont-plus-circle me-2 fs-6" />
                    {'Add Task'}
                  </button>
                </div>
              </div>
            </div>
          </div> {/* Row end  */}
          <Table<ITaskItem>
            columns={columns}
            loading={loading}
            dataSource={tasks}
            className={'task-table'} />
        </div>
      </div>
      <ModalComponent onClose={() => setCurrentTask(undefined)} ref={modalTask}>
        <ModalTask
          projectId={projectId ? projectId : currentTask?.projectId}
          task={currentTask}
          onClose={onCloseModal} />
      </ModalComponent>
    </>
  );
};

export default TaskGrid;
