import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import ModalComponent from '../../../../components/modal';
import { RefModal } from '../../../../components/modal/types';
import { disableLoading, enableLoading } from '../../../../global/slice';
import ApiUtils from '../../../../global/services';
import { notify } from '../../../../global/helpers';
import { useMemo } from 'react';
import { IProjectItem } from '../../../project/types';
import { PROJECT_ALL } from '../../../project/api';
import ProjectItem from '../../../project/components/ProjectItem';
import ModalProject from '../../../project/components/ModalProject';

const ProjectAll = (): JSX.Element =>
{
    
  const { viewMode, user } = useAppSelector(state => ({
    viewMode : state.project.viewMode,
    user     : state.user.information
  }));
  const dispatch = useAppDispatch();
    
  const [projectList, setProjectList] = useState<IProjectItem[]>([]);
  const [dataItem, setDataItem] = useState<IProjectItem>();
    
  const modalProject = useRef<RefModal>(null);
    
  useEffect(() => {
    fetchData();
  }, [viewMode]);
    
  const fetchData = async () =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({
      api: `${ PROJECT_ALL }`,
    });
      
    if (response.success)
    {
      setProjectList(response.data);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };

    
  //   const onChangeViewMode = (viewMode: 'all' | 'starred') => () =>
  //   {
  //     if (viewMode === 'all')
  //     {
  //       dispatch(viewAllProject());
  //     } else
  //     {
  //       dispatch(viewStarredProject());
  //     }
  //   };

  const onOpenModalEdit = (project: IProjectItem) =>
  {
    setDataItem(project);
    modalProject.current?.show();
  };

  const onOpenModalCreate = () =>
  {
    setDataItem(undefined);
    modalProject.current?.show();
  };
    
  const onCloseModal = (refresh: boolean) =>
  {
    if (refresh)
    {
      fetchData();
    }
    modalProject.current?.hide();
  };

  
  const renderHeader = () =>
  {
    return (
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h3 className="fw-bold py-3 mb-0">Projects</h3>
            <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
              <button type="button" className="btn btn-dark w-sm-100" onClick={onOpenModalCreate}>
                <i className="icofont-plus-circle me-2 fs-6" />
                {'Create Project'}
              </button>
              {/* <ul className="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                <li className="nav-item">
                  <span onClick={onChangeViewMode('all')} className={`clickable-area nav-link ${ viewMode === 'all' && 'active'}`}>
                    {'All'}
                  </span>
                </li>
                <li className="nav-item">
                  <span onClick={onChangeViewMode('starred')} className={`clickable-area nav-link ${ viewMode === 'starred' && 'active'}`}>
                    {'Started'}
                  </span>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
    
  const renderProjectList = () =>
  {
    return projectList?.map((project) =>
    {
      return (
        <ProjectItem canEdit={true} onEditProject={onOpenModalEdit} key={project.id} {...project}/>
      );
    });
  };
    

  const renderContent = () =>
  {
    return useMemo(() =>
    {
      return (
        <div className="container-xxl">
          {renderHeader()}
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 flex-column">
              <div className="tab-content mt-4">
                <div className="tab-pane fade show active" id="All-list">
                  <div className="row g-3 gy-5 py-3 row-deck">
                    {renderProjectList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }, [projectList]);
  };
    
  return (
    <div className="body d-flex py-lg-3 py-md-2 project-list-container">
      {renderContent()}
      <ModalComponent ref={modalProject}>
        <ModalProject project={dataItem} onClose={onCloseModal}/>
      </ModalComponent>
    </div>  
  );
};

export default ProjectAll;
