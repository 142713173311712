/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { getUA } from 'react-device-detect';
import { useAppDispatch, } from '../../../../app/hooks';
import { GOOGLE_CLIENT_ID } from '../../../../global/constants';
import { loginServer } from '../../actions';
import { ILoginForm } from '../../types';



const Login = () : JSX.Element =>
{

  const dispatch = useAppDispatch();

    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoginSuccess = (data: any) =>
  {
    const loginData : ILoginForm = {
      accessToken : data.accessToken,
      email       : data.profileObj.email,
      avatarUrl   : data.profileObj.imageUrl,
      name        : `${ data.profileObj.familyName } ${ data.profileObj.givenName }`,
      userAgent   : getUA
    };
    dispatch(loginServer(loginData));
  };

  const renderAppInformation = () =>
  {
    return (
      <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
        <div style={{ maxWidth: '25rem' }}>
          <div className="text-center mb-5">
            <svg width="4rem" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
            </svg>
          </div>
          <div className="mb-5">
            <h2 className="color-900 text-center">{'iTask - Task management'}</h2>
          </div>
          {/* Image block */}
          <div >
            <img className={'img-banner'} src="./assets/images/task-management-banner.jpeg" alt="iTask banner" />
          </div>
        </div>
      </div>
        
    );
  };
    
  const renderLoginForm = () =>
  {
    return (
      <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
        <div className="w-100 p-3 p-md-5 card border-0 bg-dark text-light" style={{ maxWidth: '32rem' }}>
          {/* Form */}
          <form className="row g-1 p-3 p-md-4">
            <div className="col-12 text-center mb-5">
              <h1 className={'sign-in-title'}>Sign in</h1>
              <span>Free access to our dashboard.</span>
            </div>
            <div className="col-12 text-center mb-4">
              {/* */}
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Login"
                onSuccess={onLoginSuccess}
                onFailure={(e) => {console.log('login google failed: ', e);}}
                // cookiePolicy={'single_host_origin'}
                render={(renderProps : any) => (
                  <div className="btn btn-lg btn-outline-secondary btn-block" onClick={renderProps.onClick} >
                    <span className="d-flex justify-content-center align-items-center">
                      <img className="avatar xs me-2" src="../assets/images/google.svg" alt="Image Description" />
                        Sign in with Google
                    </span>
                  </div>
                )}
              />
              {/* <span className="dividers text-muted mt-4">OR</span> */}
            </div>
            {/* <div className="col-12">
              <div className="mb-2">
                <label className="form-label">Email address</label>
                <input type="email" className="form-control form-control-lg" placeholder="name@example.com" />
              </div>
            </div> */}
            {/* <div className="col-12">
              <div className="mb-2">
                <div className="form-label">
                  <span className="d-flex justify-content-between align-items-center">
                      Password
                    <a className="text-secondary" href="auth-password-reset.html">Forgot Password?</a>
                  </span>
                </div>
                <input type="password" className="form-control form-control-lg" placeholder="***************" />
              </div>
            </div> */}
            {/* <div className="col-12">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    Remember me
                </label>
              </div>
            </div> */}
            {/* <div className="col-12 text-center mt-4">
              <a href="/login" onClick={onLoginSuccess} className="btn btn-lg btn-block btn-light lift text-uppercase">
                {'SIGN IN'}
              </a>
            </div> */}
            <div className="col-12 text-center mt-4">
              <span className="text-muted">Don't have an account yet? Contact your manager to create an account</span>
            </div>
          </form>
          {/* End Form */}
        </div>
      </div>
       
    );
  };

  return (
    <div id="mytask-layout" className="theme-indigo">
      {/* main body area */}
      <div className="main p-2 py-3 p-xl-5 ">
        {/* Body: Body */}
        <div className="body d-flex p-0 p-xl-5 login-page">
          <div className="container-xxl">
            <div className="row g-0">
              {renderAppInformation()}
              {renderLoginForm()}            
            </div> 
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default Login;
