export const ROUTES = {
  dashboard      : '/dashboard',
  projects       : '/projects',
  projectDetails : '/project/:id',
  tasks          : '/tasks',
  taskDetails    : '/task/:id',
  profile        : '/profile',
  login          : '/login',
  users          : '/users',
  projectAll     : '/project-all',
};