import React, { useEffect, useState } from 'react';
import
{
  Select,
  Form,
  Input,
  DatePicker,
} from 'antd';
import { PropModalProject } from '../../../components/modal/types';
import ApiUtils from '../../../global/services';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ICreateProject } from '../types';
import { USER_LIST_ACTIVE } from '../../user/api';
import { disableLoading, enableLoading } from '../../../global/slice';
import { notify } from '../../../global/helpers';
import { PROJECT_CREATE, PROJECT_UPDATE } from '../api';
import moment, { Moment } from 'moment';
import { IUserItem } from '../../user/types';

const { TextArea } = Input;
const { Option } = Select;

const ModalProject = (props: PropModalProject): JSX.Element =>
{
  const user = useAppSelector(state => state.user.information);
  const dispatch = useAppDispatch();

  const defaultValues = props.project?.id ? {
    name        : props.project.name,
    description : props.project.description,
    endDate     : props.project.endDate ? moment(props.project.endDate ) : null,
    startDate   : props.project.startDate ?moment(props.project.startDate): null,
    userJoined  : props.project.userJoined.map(urs => urs.id)
  } : {
    name        : '',
    description : '',
    userJoined  : [user.id]
  };
    
  
  const [userList, setUserList] = useState<IUserItem[]>([]);
  const [startDateValue, setStartDateValue] = useState<Moment | null>(defaultValues.startDate || null);
  useEffect(() =>
  {
    fetchListUsers();
  }, [props.project?.id]);
    
  const fetchListUsers = async () =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({ api: USER_LIST_ACTIVE });
      
    if (response.success)
    {
      setUserList(response.data);
    } else
    {
      notify({ type: 'error', message: response.message || 'Cannot load user list' });
    }
    dispatch(disableLoading());
  };
    
  const onCreateProject = async (data: ICreateProject) =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({ api: PROJECT_CREATE, method: 'post', data });
    if (response.success)
    {
      notify({ type: 'success', message: 'Successfully created new project' });
      props.onClose?.(true);
    } else
    {
      notify({ type: 'error', message: response.message });
    }
    dispatch(disableLoading());
  };
    
  const onUpdateProject = async (id: string, data: ICreateProject) =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({ api: `${PROJECT_UPDATE}/${id}`, method: 'post', data });
    if (response.success)
    {
      notify({ type: 'success', message: 'Project update successful' });
      props.onClose?.(true);
    } else
    {
      notify({ type: 'error', message: response.message });
    }
    dispatch(disableLoading());
  };
    

  const onFinish = (values: ICreateProject) =>
  {
    const data: ICreateProject = {
      name        : values.name,
      description : values.description,
      userJoined  : values.userJoined,
      startDate   : values.startDate ? moment(values.startDate).format('DD-MM-YYYY') : null,
      endDate     : values.endDate ? moment(values.endDate).format('DD-MM-YYYY') : null
    }; 
    if (props.project?.id)
    {
      onUpdateProject(props.project.id, data);
    } else
    {
      onCreateProject(data);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
    
  const disabledDate = (current: any) =>
  {
    return current && startDateValue && current < startDateValue;
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={defaultValues}
    >
      <div className="">
        <h5>{ defaultValues.name ? 'Update Project' : 'Create Project'}</h5>
        <div className="mb-3">
          <label className="form-label">Project Name</label>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please input your project name!' }]}
          >
            <Input
              placeholder={'Enter project name'}
            />
          </Form.Item>
        </div>
        <div className="deadline-form">
          <div className="row g-3 mb-3">
            <div className="col">
              <label htmlFor="datepickerded" className="form-label">Project Start Date</label>
              <Form.Item
                name="startDate"
                             
              >
                <DatePicker
                  className={'form-control'}
                  format={'DD/MM/YYYY'}
                  onChange={(e) =>
                  {
                    console.log(e, 'e');
                                      
                    setStartDateValue(e);
                  }}
                />
              </Form.Item>
            </div>
            <div className="col">
              <label htmlFor="datepickerdedone" className="form-label">Project End Date</label>
              <Form.Item
                name="endDate"
                dependencies={['startDate']}
              >
                <DatePicker
                  className={'form-control'}
                  format={'DD/MM/YYYY'}
                  disabled={!Boolean(startDateValue)}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-sm-12">
              <label htmlFor="formFileMultipleone" className="form-label">Members</label>
              <Form.Item
                name="userJoined"
                rules={[{ required: true, message: 'Please select project member!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select members"
                >
                  {userList.map((urs) => (<Option key={urs.id} value={urs.id} >{urs.name}</Option>))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea78" className="form-label">Description</label>
         
          <Form.Item
            name="description"
          >
            <TextArea
              placeholder={'Enter project description'}
              rows={3}
            />
          </Form.Item>
        </div>
      </div>
      <div className="modal-footer">
        <Form.Item >
          <button type="submit" className="btn btn-primary">
            {props.project?.id ? 'Update' : 'Create'}
          </button>
        </Form.Item>
        
      </div>
    </Form>
  ); 
};

export default ModalProject;
