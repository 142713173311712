import { combineReducers } from 'redux';
import appReducers from '../global/slice';
import projectReducers from '../modules/project/slice';
import userReducers from '../modules/user/slice';

const rootReducer = combineReducers({
  user    : userReducers,
  app     : appReducers,
  project : projectReducers
});

export default rootReducer;