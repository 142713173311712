export const USER_CHECK_LOGIN = '/authentic/check-login';
export const USER_LOGIN = '/authentic/login';
export const USER_LOGOUT = '/authentic/logout';
export const USER_LIST_ACTIVE = '/user/list-active';
export const USER_LIST = '/user/list';
export const USER_CREATE = '/user/create';
export const USER_UPDATE = '/user/update';

export const SERVICE_NOTIFICATIONS = '/service/notifications';
export const SERVICE_NOTIFICATION_READ = '/service/notifications/read';

