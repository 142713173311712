import { createSlice } from '@reduxjs/toolkit';
import { IUser } from './types';
import { IAction } from '../../app/types';



const initialUser: IUser = {
  information: {
    name                 : '',
    id                   : '',
    email                : '',
    avatarUrl            : '',
    department           : '',
    position             : '',
    numOfProjects        : 0,
    numOfTasks           : 0,
    numOfInprogressTasks : 0,
    numOfTodoTasks       : 0
  },
  isAuthenticated : false,
  errorMessage    : '',
  loading         : true,
  isLoginChecked  : false,
};


const userSlice = createSlice({
  name         : 'user',
  initialState : initialUser,
  reducers     : {
    loginRequest(state)
    {
      return {
        ...state,
        loading: true
      };
    },
    checkLoginRequest(state)
    {
      return {
        ...state,
        loading: true
      };
    },
    loginSuccess(state, action: IAction)
    {
      return {
        ...state,
        isAuthenticated : true,
        information     : action.payload,
        errorMessage    : '',
        loading         : false,
        isLoginChecked  : true
      };
    },
    loginFailed(state, action: IAction)
    {
      return {
        ...initialUser,
        errorMessage   : action.payload.message || '',
        loading        : false,
        isLoginChecked : true
      };
    },
    logoutRequest(state)
    {
      return {
        ...state,
        loading: true
      };
    },
    logoutSuccess()
    {
      return {
        ...initialUser,
        loading        : false,
        isLoginChecked : true
      };
    },
    logoutFailed(state, action: IAction)
    {
      return {
        ...state,
        errorMessage : action.payload.message || '',
        loading      : false
      };
    },
  }
});

export const { logoutSuccess, logoutFailed, loginSuccess, loginFailed, loginRequest, checkLoginRequest, logoutRequest } = userSlice.actions;

const userReducers = userSlice.reducer;
export default userReducers;