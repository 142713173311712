import { Carousel, Image, Input } from 'antd';
import moment from 'moment';
import React from 'react';
import { useAppSelector } from '../../../../../app/hooks';
import { API, defaultAvatar } from '../../../../../global/constants';
import { ITask } from '../../../types';

interface IInformationTask
{
  task: ITask | undefined;
  status: any;
  onPostComment: () => void;
  onChangeComment: (e: string) => void;
}
const { TextArea } = Input;

const InformationTask = (props: IInformationTask): JSX.Element =>
{
  const { task, status, onPostComment, onChangeComment } = props;
  const user = useAppSelector(state => state.user.information);
    
    
  const renderStatus = () =>
  {
    return (
      <div className="card ">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div style={{ backgroundColor: status.color }} className="avatar lg  rounded-1 no-thumbnail bg color-defult">
              <i style={{ color: '#fff', fontSize: 30 }} className={status.icon} />
            </div>
            <div className="flex-fill ms-4 text-truncate">
              <div className="text-truncate">{'Status'}</div>
              <span className="badge" style={{ backgroundColor: status.color }}>
                {status?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
    
  const renderCreatedBy = () =>
  {
    return (
      <div className="card ">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="avatar lg  rounded-1 no-thumbnail bg-lightblue color-defult">
              <Image
                placeholder={''}
                width={45}
                className="avatar rounded-circle"
                src={task?.createdBy?.avatarUrl || defaultAvatar}
              />
            </div>
            <div className="flex-fill ms-4 text-truncate">
              <div className="text-truncate">
                {'Created Name'}
              </div>
              <span className="fw-bold">
                {task?.createdBy?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
    
  const renderActivity = () =>
  {
    return (
      <div className="card">
        <div className="card-body card-body-height py-4">
          <div className="row">
            <div className="col-lg-12 col-md-12 task-activities-log">
              <div className="card-header py-3">
                <h6 className="mb-0 fw-bold ">Recent Activity</h6>
              </div>
              <div className="card-body mem-list activity-list">
                {task?.activities.map(activity =>
                {
                  const activityColor = activity.activityType === 'REMOVE_FROM_PROJECT' || activity.activityType === 'UNASSIGNED_TASK' ? 'ti-danger' : activity.activityType === 'CREATE_TASK' ? 'ti-success' : 'ti-info';
                  return (
                    <div key={activity.id} className={`timeline-item ${activityColor} border-bottom ms-2`}>
                      <div className="d-flex">
                        <span className="avatar d-flex justify-content-center align-items-center rounded-circle light-success-bg">
                          <img className="avatar rounded-circle small-avt" src={activity.user?.avatarUrl || defaultAvatar}/>
                        </span>
                        <div className="flex-fill ms-3">
                          <div className="mb-1">
                            {activity.activityContent}
                          </div>
                          <span className="d-flex text-muted">
                            {moment(activity.createdAt).calendar()}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> 
          </div> 
        </div>
      </div>
    );
  };
    
  const renderAssignee = () =>
  {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="avatar lg  rounded-1 no-thumbnail bg-lightgreen color-defult">
              <Image
                width={45}
                className="avatar rounded-circle"
                src={task?.assignee?.avatarUrl || defaultAvatar}
              />
            </div>
            <div className="flex-fill ms-4 text-truncate">
              <div className="text-truncate">
                {'Assignee'}
              </div>
              <span className="fw-bold">
                {task?.assignee?.name}
              </span>
            </div>
          </div>
        </div>
      </div> 
    );
  };
    

  const renderAttachImage = () =>
  {
    return (
      <div className="col-lg-12 col-md-12 mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="fw-bold mb-3 text-danger">Attached Images</h6>
            <div className="flex-grow-1">
              <Carousel
                className={'attachment-carousel'} 
                autoplay>
                {task?.attachments.map(element =>
                {
                  return (
                    <div style={{ objectFit: 'cover' }} key={element.id}>
                      <Image
                        
                        style={{ objectFit: 'cover' }}
                        width={'100%'}
                        height={240}
                        src={`${API.DOMAIN}/${element.fileUrl}`}
                      />
                    </div>
                  );
                })}
                
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  };
    
  const renderComments = () =>
  {
    return (
      <div className="card mb-3">
        <div className="card-body card-body-height py-4">
          <div className="row">
            <div className="col-lg-12 col-md-12 comment-list">
              <div className="timeline-item-post">
                <div className="comment-header">
                  <i className="far fa-comment-alt"></i>
                  <h6 className="mb-0 fw-bold ">{`Comment (${task?.comments?.length || 0})`}</h6>
                </div>
                <div>
                  {task?.comments.map((comment) => (
                    <div key={comment.id} className="d-flex mt-3 pt-3 border-top">
                      <img className="avatar rounded-circle" src={comment.createdBy?.avatarUrl || defaultAvatar} />
                      <div className="flex-fill ms-3 text-truncate">
                        <p className="mb-0"><span>
                          {comment.createdBy?.name}
                        </span> <small className="msg-time">
                          {moment(comment.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                        </small></p>
                        <span className="text-muted">
                          {comment.content}
                        </span>
                      </div>
                    </div>
                  ))
                  }
                </div>
                <div className="comment-box card mb-2">
                  <div className="card-body">
                    <div className="post">
                      <TextArea
                        rows={4}
                        showCount
                        allowClear={true}
                        maxLength={500}
                        onChange={(event) => onChangeComment(event.target.value)} />
                      <div className="py-3">
                        {/* <a href="#" className="px-3 " title="upload images"><i className="icofont-ui-camera" /></a>
                    <a href="#" className="px-3 " title="upload video"><i className="icofont-video-cam" /></a>
                    <a href="#" className="px-3 " title="Send for signuture"><i className="icofont-pen-alt-2" /></a> */}
                        <div className={'comment-input'}>
                          <img className="avatar rounded-circle" src={user?.avatarUrl || defaultAvatar} alt="profile" />
                          <button
                            onClick={onPostComment}
                            className="comment-label btn btn-primary float-sm-end  mt-2 mt-sm-0">
                            {'Add comment'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  };
  return (
    <div className="row g-3 row-deck">
      <div className="row g-3">
        <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12">
          <div className="row g-3">
            <div className="col-md-12">
              {renderAttachImage()}
              {renderComments()}
            </div>
          </div> 
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12">
          {renderStatus()}
          {renderCreatedBy()}
          {renderAssignee()}
          {renderActivity()}
        </div>
      </div>
        
    </div>
  );
};

export default InformationTask;