import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Collapse, Popover } from 'antd';
import { sidebarConfig } from './types';
import { useState } from 'react';

const { Panel } = Collapse;

const AppSideBar = (): JSX.Element =>
{

  const history = useHistory();
  const location = useLocation();
    
  const [active, setActive] = useState('1');
  const [collapsed, setCollapsed] = useState(false);
    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNavigate = (path: string, key: string) => (e: any) : void =>
  {
    e.preventDefault();
    history.push(path);
    setActive(key);
  };
    
  const renderHome = () =>
  {
    return (
      <Link to="/" className="mb-0 brand-icon">
        <span className="logo-icon">
          <svg width={35} height={35} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
          </svg>
        </span>
        {!collapsed && <span className="logo-text">{'iTask'}</span>}
      </Link>
    );
  };
    
    
  if (collapsed)
    return (
      <div className="collapsed-sidebar sidebar px-4 py-4 py-md-5 me-0 itask-sidebar">
        <div className="d-flex flex-column h-100 sidebar-container">
          {renderHome()}
          <ul className="menu-list flex-grow-1 mt-3">
            {sidebarConfig?.map((routes =>
            {
              const content = <span className="route-text">{routes.name}</span>;
          
              return (
                <Popover
                  className={'collapsed-popover'}
                  placement={'right'}
                  key={routes.key}
                  content={content}>
                  <a className={`m-link ${active.includes(routes.key) ? 'active' : '' }`} href="#" onClick={onNavigate(routes.route, routes.key)}>
                    <i className={`${routes.icon} collapsed-icon`} />
                  </a>
                </Popover>
              );
             
            }))}
          </ul>
          <button onClick={() => setCollapsed(!collapsed)} type="button" className="btn btn-link sidebar-mini-btn text-light">
            <span className="ms-2">
              <i className="fas fa-angle-double-right"></i>
            </span>
          </button>
        </div>
      </div>
    );

  return (
    <div className="sidebar px-4 py-4 py-md-5 me-0 itask-sidebar">
      <div className="d-flex flex-column h-100">
        {renderHome()}
        <ul className="menu-list flex-grow-1 mt-3">
          <Collapse
            expandIcon={(a) =>
            {
              if (a.isActive)
              {
                return (
                  <i className="fas fa-chevron-down expand-icon-itask"></i>
                );
              }
              return (
                <i className="fas fa-chevron-right expand-icon-itask"></i>
              );
            }}
            expandIconPosition={'right'}
            defaultActiveKey={['1']}
            ghost
            bordered={false}
            onChange={(value) => console.log('value: ', value)}
          >
            {sidebarConfig?.map((routes =>
            {
              // const isActive = routes.children.filter((element) => element.route === location.pathname).length > 0;
              if (routes.children)
              {
                return (
                  <Panel header={(
                    <a className={`m-link ${active.includes(routes.key) ? 'active' : '' }`} href="#" onClick={onNavigate(routes.route, routes.key)}>
                      <i className={`${routes.icon} side-bar-icon`} /> <span>
                        {routes.name}
                      </span>
                    </a>
                  )}
                  key={routes.key}>
                    {routes.children?.map(route =>
                    {
                      return (
                        <a key={route.name} className={`ms-link ${location.pathname === route.route ? 'active' : ''}`} href="#" onClick={onNavigate(route.route, routes.key)} >
                          <span>
                            {route.name}
                          </span>
                        </a>
                      );
                    })}
                  </Panel>
                );
              }
              return (
                <div key={routes.key}>
                  <a className={`m-link ${active.includes(routes.key) ? 'active' : '' }`} href="#" onClick={onNavigate(routes.route, routes.key)}>
                    <i className={`${routes.icon} side-bar-icon`} /> <span>
                      {routes.name}
                    </span>
                  </a>
                </div>
              );
                
            }))}
          </Collapse>
        </ul>
        <button onClick={() => setCollapsed(!collapsed)} type="button" className="btn btn-link sidebar-mini-btn text-light">
          <span className="ms-2">
            <i className="fas fa-angle-double-left"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default AppSideBar;
