export interface IAppLayout
{
  children: JSX.Element
}

interface ISideBar
{
  key: string,
  name: string,
  icon: string,
  route: string,
  children?: ISideBarItem[]
}

interface ISideBarItem
{
  name: string,
  route: string
}

export interface INotificationService {
  data: INotification[];
  unread: number;
}
export interface INotification {
  id: string;
  notiType:
  | 'ASSIGN_TASK'
  | 'UNASSIGNED_TASK'
  | 'UPDATE_STATUS_TASK'
  | 'ADD_TO_PROJECT'
  | 'REMOVE_FROM_PROJECT'
  | 'CREATE_USER';
  notiContent: string;
  read: boolean;
  createdAt: string;
}

export const sidebarConfig : ISideBar[] = [
  {
    key   : '1',
    name  : 'Dashboard',
    icon  : 'fas fa-chart-line',
    route : '/dashboard',
   
  },
  {
    key   : '2',
    name  : 'Projects',
    icon  : 'fas fa-briefcase',
    route : '/projects',
  },
  {
    key   : '3',
    name  : 'My Task',
    icon  : 'fas fa-clipboard-check',
    route : '/tasks',
  },
  {
    key      : '4',
    name     : 'Admin',
    icon     : 'fas fa-users-cog',
    route    : '/users',
    children : [
      {
        name  : 'Users',
        route : '/users'
      },
      {
        name  : 'Projects',
        route : '/project-all'
      }
    ]
  }
];