import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ApiUtils from '../global/services';
import { ROUTES } from '../routes/types';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logoutServer } from '../modules/user/actions';
import { Popover } from 'antd';
import { SERVICE_NOTIFICATIONS, SERVICE_NOTIFICATION_READ } from '../modules/user/api';
import { INotificationService } from './types';
import { notify } from '../global/helpers';
import { useEffect } from 'react';
import moment from 'moment';


const AppHeader = (): JSX.Element =>
{
  const history = useHistory();
    
  const dispatch = useAppDispatch();
    
  const user = useAppSelector(state => state.user.information);
    
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState<INotificationService>();
    
  const onViewProfile = () =>
  {
    history.push(ROUTES.profile);
  };
    
  useEffect(() => {
    fetchData();
  }, []);
    
  const fetchData = async () =>
  {
    const response = await ApiUtils.request({
      api: `${SERVICE_NOTIFICATIONS}`,
    });
      
    if (response.success)
    {
      console.log('response: ', response.data);
      setNotifications(response.data);
    } else
    {
      notify({ type: 'error', message: response.message || 'Can not load notifications!' });
    }
  };
    
    
  const onReadNotification = async (id: string) => {
    const response = await ApiUtils.request({
      api    : SERVICE_NOTIFICATION_READ,
      method : 'post',
      data   : { ids: [id]},
    });
    if (response.success)
    {
      fetchData();
    }
  };
    
    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLogout = (e: any) =>
  {
    e.preventDefault();
    dispatch(logoutServer());
  };
    
  const renderUserProfile = () =>
  {
    return (
      <Popover
        content={(
          <div className="">
            <div className="card border-0 w280">
              <div className="card-body pb-0 clickable-area">
                <div onClick={onViewProfile} className="d-flex py-1 itask-user-profile">
                  <img className="avatar rounded-circle" src={user?.avatarUrl} alt="profile" />
                  <div className="flex-fill ms-3">
                    <p className="mb-0"><span className="font-weight-bold">
                      {user.name}
                    </span></p>
                    <small >
                      {user.email}
                    </small>
                  </div>
                </div>
                <div><hr className="dropdown-divider border-dark" /></div>
              </div>
              <div className="list-group m-2 ">
                <Link to="/tasks" onClick={()=>setVisible(false)} className="list-group-item list-group-item-action border-0 "><i className="icofont-tasks fs-5 me-3" />{'My Task'}</Link>
                <Link to="/projects" onClick={()=>setVisible(false)} className="list-group-item list-group-item-action border-0 "><i className="icofont-briefcase fs-5 me-3" />{'Projects'}</Link>
                <a onClick={onLogout} href="/logout" className="list-group-item list-group-item-action border-0 "><i className="icofont-logout fs-6 me-3" />{'Log out'}</a>
                {/* <div><hr className="dropdown-divider border-dark" /></div>
                <a href="ui-elements/auth-signup.html" className="list-group-item list-group-item-action border-0 "><i className="icofont-contact-add fs-5 me-3" />Add personal account</a> */}
              </div>
            </div>
          </div>
        )}
        onVisibleChange={(visible)=>setVisible(visible)}
        visible={visible}
        trigger={'click'}
      >
        <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
          <div className="u-info me-2">
            <p className="mb-0 text-end line-height-sm "><span className="font-weight-bold">
              {user.name}
            </span></p>
            <small>
              {user.role || '_'}
            </small>
          </div>
          <a className="nav-link dropdown-toggle pulse p-0" href="#" role="button" data-bs-toggle="dropdown" data-bs-display="static">
            <img className="avatar lg rounded-circle img-thumbnail" src={user?.avatarUrl} alt="profile" />
          </a>
        </div>  
      </Popover>
    );
  };
    
  const renderNotifications = () =>
  {
    return (
      <div style={{ overflow  : 'auto',
        maxHeight : 600,
        maxWidth  : 500, }}>
        {notifications?.data.map((notification) =>
        {
          const type =
          notification.notiType === 'ADD_TO_PROJECT' ||
          notification.notiType === 'REMOVE_FROM_PROJECT'
            ? 'project'
            : 'task';

          return (
            <li
              onClick={() => onReadNotification(notification.id)}
              key={notification.id} className="py-2 mb-1 border-bottom clickable-area" style={{
                opacity: notification.read ? 0.5 : 1,
              }}>
              <div className="d-flex">
                <div style={{
                  backgroundColor : type === 'project' ? '#2874A6' : '#148F77',
                  width           : 35,
                  height          : 35,
                  minWidth        : 35,
                  minHeight       : 35,
                  display         : 'flex',
                  alignItems      : 'center',
                  justifyContent  : 'center',
                  borderRadius    : 35/2,
                }}>
                  {type === 'project' ?
                    <i style={{ color: '#fff' }} className="fas fa-project-diagram"></i> :
                    <i style={{ color: '#fff' }} className="fas fa-tasks"></i>}
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', }} className="flex-fill ms-2">
                  <span style={{
                    lineHeight : '24px',
                    color      : notification.read ? '#333' : '#484c7f',
                  }} >
                    {notification.notiContent}
                  </span>
                  <span style={{ marginTop: 4, fontSize: 12, color: '#ccc' }}>
                    {moment(notification.createdAt).calendar()}
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </div>
    );
  };

  return (
    <div className="header">
      <nav className="navbar py-4">
        <div className="container-xxl">
          {/* header rightbar icon */}
          <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
            <div className="d-flex">
            </div>
            <Popover placement="bottom" title={'Notifications'} content={renderNotifications()} trigger="click">
              <div className="dropdown notifications zindex-popover clickable-area">
                <div className="nav-link dropdown-toggle pulse" >
                  <i className="icofont-alarm fs-5" style={{ color: notifications?.unread && notifications.unread > 0 ? '#CB4335' : '#333' }} />
                  <span className="pulse-ring" style={{ backgroundColor: notifications?.unread && notifications.unread > 0 ? '#484c7f' : 'unset' }} />
                  {notifications && notifications.unread > 0 &&
                                  <div style={{
                                    position        : 'absolute',
                                    top             : 3,
                                    right           : 12,
                                    fontSize        : 10,
                                    width           : 15,
                                    height          : 15,
                                    borderRadius    : 8,
                                    backgroundColor : '#CB4335',
                                    borderWidth     : 1,
                                    borderColor     : '#fff',
                                    display         : 'flex',
                                    alignItems      : 'center',
                                    justifyContent  : 'center',
                                    color           : '#fff',
                                  }}>
                                    {notifications.unread < 10 ? notifications.unread : '9+'}
                                  </div>
                  }
                </div>
              </div>
            </Popover>
            {renderUserProfile()}
          </div>
          {/* main menu Search*/}
          <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">
            {/* <div className="input-group flex-nowrap input-group-lg">
              <button type="button" className="input-group-text" id="addon-wrapping"><i className="fa fa-search" /></button>
              <input type="search" className="form-control" placeholder="Search" aria-label="search" aria-describedby="addon-wrapping" />
              <button type="button" className="input-group-text add-member-top" id="addon-wrappingone" data-bs-toggle="modal" data-bs-target="#addUser"><i className="fa fa-plus" /></button>
            </div> */}
          </div>
        </div>
      </nav>
    </div>
    
  );
};

export default AppHeader;
