import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { ROUTES } from '../../../../routes/types';



const Dashboard = (): JSX.Element =>
{

  const { user } = useAppSelector(state => ({
    user: state.user.information
  }));
    
  const renderBody = () =>
  {
    return (
      <div></div>
    );
  };
    
  return (
    <>
      {/* Body: Body */}
      <div className="body d-flex py-3">
        <div className="container-xxl">
          <div className="row g-3 mb-3 row-deck">
            <div className="col-md-12 col-lg-4 col-xl-4 col-xxl-4">
              <div className="card ">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar lg  rounded-1 no-thumbnail bg-lightyellow color-defult"><i className="bi bi-journal-check fs-4" /></div>
                    <div className="flex-fill ms-4">
                      <div >Total Task</div>
                      <h5 className="mb-0 ">
                        {user.numOfTasks}
                      </h5>
                    </div>
                    <Link to={ROUTES.tasks} title="space-used" className="btn btn-link text-decoration-none  rounded-1"><i className="icofont-hand-drawn-right fs-2 " /></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-xl-4 col-xxl-4">
              <div className="card ">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar lg  rounded-1 no-thumbnail bg-lightblue color-defult"><i className="bi bi-list-check fs-4" /></div>
                    <div className="flex-fill ms-4">
                      <div >Inprogress Task</div>
                      <h5 className="mb-0 ">
                        {user.numOfInprogressTasks}
                      </h5>
                    </div>
                    <Link to={ROUTES.tasks} title="space-used" className="btn btn-link text-decoration-none  rounded-1"><i className="icofont-hand-drawn-right fs-2 " /></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-xl-4 col-xxl-4">
              <div className="card ">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar lg  rounded-1 no-thumbnail bg-lightgreen color-defult"><i className="bi bi-clipboard-data fs-4" /></div>
                    <div className="flex-fill ms-4">
                      <div >Project</div>
                      <h5 className="mb-0 ">
                        {user.numOfProjects}
                      </h5>
                    </div>
                    <Link to={ROUTES.projectAll} title="renewal-date" className="btn btn-link text-decoration-none  rounded-1"><i className="icofont-hand-drawn-right fs-2 " /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderBody()}
        </div>             
      </div>
    </>
  );
};

export default Dashboard;
