import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { defaultAvatar } from '../../../global/constants';
import { IProjectItemProps } from '../types';

const ProjectItem = (props: IProjectItemProps): JSX.Element =>
{
  const history = useHistory();
  return (
    <div key={props.id} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12" onClick={()=>history.push(`/project/${props.id}`)}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mt-5">
            <Link to={`/project/${props.id}`}>
              <div className="lesson_name">
                <div className="project-block light-info-bg">
                  <svg width={35} height={35} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                </div>
                <span className="small text-muted project_name fw-bold">
                  {props.name}
                </span>
                {/* <h6 className="mb-0 fw-bold  fs-6  mb-2">UI/UX Design</h6> */}
              </div>
            </Link>
            {props.canEdit && (
              <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button type="button" className="btn btn-outline-secondary"
                  onClick={
                    (event) =>
                    {
                      event.preventDefault();
                      event.stopPropagation();
                      props.onEditProject?.(props);
                    }}
                >
                  <i className="icofont-edit text-success" />
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={
                  (event) =>
                  {
                    event.preventDefault();
                    event.stopPropagation();
                    props.onDeleteProject?.(props);
                  }}><i className="icofont-ui-delete text-danger" /></button>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="avatar-list avatar-list-stacked pt-2">
              {props.userJoined?.map((urs) => (
                <img key={urs.id} className="avatar rounded-circle sm" src={urs?.avatarUrl || defaultAvatar} />
              ))}
            </div>
          </div>
          <div className="row g-2 pt-4">
            <div className="col-6">
              <div className="d-flex align-items-center">
                <i className="icofont-paper-clip" />
                <span className="ms-2">
                  {`${props.numOfAttachment || 0} Attach`}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center">
                <i className="icofont-sand-clock" />
                <span className="ms-2">
                  {`${moment(props.endDate).diff(moment(props.startDate), 'month') || '_'} Month`}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center">
                <i className="icofont-group-students " />
                <span className="ms-2">
                  {`${props.userJoined.length} Members`}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center">
                <i className="fa fa-tasks" aria-hidden="true"></i>
                <span className="ms-2">
                  {props.numOfTotalTask}
                </span>
              </div>
            </div>
          </div>
          <div className="dividers-block" />
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h4 className="small fw-bold mb-0">Progress</h4>
            <span className={`small ${moment(props.endDate).diff(moment(), 'day') > 0 ? 'light-success-bg ':'light-danger-bg'}  p-1 rounded`}><i className="icofont-ui-clock" />
              {`${moment(props.endDate).diff(moment(), 'day') < 0 ? ' Out of date': ` ${moment(props.endDate).diff(moment(), 'day') || '_'} days left`}`}
            </span>
          </div>
          <div className="progress" style={{ height: 8 }}>
            <div className="progress-bar bg-secondary" role="progressbar" style={{ width: `${props.numOfCompletedTask/props.numOfTotalTask*100 || 0}%` }}/>
          </div>
        </div>
      </div>
    </div>
       
  );
};


// const ProjectItem = (props: IProject) : JSX.Element => {
//   return (
//     <div key={props.id} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
//       <div className="card">
//         <div className="card-body">
//           <div className="d-flex align-items-center justify-content-between mt-5">
//             <div className="lesson_name">
//               <div className="project-block light-info-bg">
//                 <i className="icofont-paint" />
//               </div>
//               <Link to={`/project/${props.id}`} >
//                 <span className="small text-muted project_name fw-bold">
//                   {`${props.name} ${props.id}`}                                  
//                 </span>
//               </Link>
//               <h6 className="mb-0 fw-bold  fs-6  mb-2"> 
//                 {props.type}
//               </h6>
//             </div>
//             <div className="btn-group" role="group" aria-label="Basic outlined example">
//               <button type="button" className="btn btn-outline-secondary"
//                 onClick={() => props.onEditProject?.(props.id)}
//               >
//                 <i className="icofont-edit text-success" />
//               </button>
//               <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#deleteproject"><i className="icofont-ui-delete text-danger" /></button>
//             </div>
//           </div>
//           <div className="d-flex align-items-center">
//             <div className="avatar-list avatar-list-stacked pt-2">
//               {props.members?.map(member => (
//                 <img key={member.id} className="avatar rounded-circle sm" src={member?.avatarUrl || '/assets/images/xs/avatar2.jpg'} />
//               ))}
//               <span
//                 onClick={() => props.onShowMember?.(props.id, props.members)}
//                 className="avatar rounded-circle text-center pointer sm" >
//                 <i className="icofont-ui-add" />
//               </span>
//             </div>
//           </div>
//           <div className="row g-2 pt-4">
//             <div className="col-6">
//               <div className="d-flex align-items-center">
//                 <i className="icofont-paper-clip" />
//                 <span className="ms-2">
//                   {`${props.numOfAttach} Attach`}
//                 </span>
//               </div>
//             </div>
//             <div className="col-6">
//               <div className="d-flex align-items-center">
//                 <i className="icofont-sand-clock" />
//                 <span className="ms-2">
//                   {`${moment(props.endDate, 'DD-MM-YYYY').diff(moment(props.startDate, 'DD-MM-YYYY'), 'month')} Month`}
//                 </span>
//               </div>
//             </div>
//             <div className="col-6">
//               <div className="d-flex align-items-center">
//                 <i className="icofont-group-students " />
//                 <span className="ms-2">
//                   {`${props.members.length} Members`}
//                 </span>
//               </div>
//             </div>
//             <div className="col-6">
//               <div className="d-flex align-items-center">
//                 <i className="icofont-ui-text-chat" />
//                 <span className="ms-2">
//                   {props.numOfComment}                                      
//                 </span>
//               </div>
//             </div>
//           </div>
//           <div className="dividers-block" />
//           <div className="d-flex align-items-center justify-content-between mb-2">
//             <h4 className="small fw-bold mb-0">Progress</h4>
//             <span className="small light-danger-bg  p-1 rounded"><i className="icofont-ui-clock" />
//               {` ${moment(props.endDate, 'DD-MM-YYYY').diff(moment(), 'day')} Days Left`}
//             </span>
//           </div>
//           <div className="progress" style={{ height: 8 }}>
//             <div className="progress-bar bg-secondary" role="progressbar" style={{ width: `${10}%` }} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} />
//             {/* danh cho project chia theo scrum */}
//             {/* <div className="progress-bar bg-secondary ms-1" role="progressbar" style={{ width: '25%' }} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
//                 <div className="progress-bar bg-secondary ms-1" role="progressbar" style={{ width: '10%' }} aria-valuenow={10} aria-valuemin={0} aria-valuemax={100} /> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default ProjectItem;
