import { createSlice } from '@reduxjs/toolkit';
import { IProjectState } from './types';



const initialState : IProjectState = {
  loading  : false,
  viewMode : 'all'
};


const userSlice = createSlice({
  name         : 'project',
  initialState : initialState,
  reducers     : {
    getProjectDataRequest(state)
    {
      return {
        ...state,
        loading: true
      };
    },
    getProjectDataDone(state)
    {
      return {
        ...state,
        loading: false
      };
    },
    viewAllProject(state)
    {
      return {
        ...state,
        viewMode: 'all'
      };
    },
    viewStarredProject(state)
    {
      return {
        ...state,
        viewMode: 'starred'
      };
    },
  }
});

export const { getProjectDataRequest, getProjectDataDone, viewAllProject, viewStarredProject } = userSlice.actions;

const projectReducers = userSlice.reducer;
export default projectReducers;