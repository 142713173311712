/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { Table, Select, Input } from 'antd';
import { debounce } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { notify } from '../../../../global/helpers';
import ApiUtils from '../../../../global/services';
import { disableLoading, enableLoading } from '../../../../global/slice';
import ModalComponent from '../../../../components/modal';
import { RefModal } from '../../../../components/modal/types';
import { IUserOfList } from '../../../user/types';
import { USER_LIST } from '../../../user/api';
import ModalUser from '../../components/ModalUser';

const { Option } = Select;
const { Search } = Input;

const UserList = (): JSX.Element =>
{

  const columns = [
    {
      title     : 'Name',
      dataIndex : 'name',
      key       : 'name',
      render    : (text: string) => (
        <div className={'user-name'}>
          <strong>
            {text || '_'}
          </strong>
        </div>
      ),
    },
    {
      title     : 'Email',
      dataIndex : 'email',
      key       : 'email',
      render    : (dueDate: string) => (
        <div className={'user-due-date center-container'}>
          {dueDate || '_'}
        </div>
      ),
    },
    {
      title     : 'Project count',
      dataIndex : 'numOfProjects',
      key       : 'numOfProjects',
      render    : (numOfProjects: string) => (
        <div className={'user-subtask center-container'}>
          {numOfProjects || '0'}
        </div>
      ),
    },
    // {
    //   title     : 'Status',
    //   key       : 'status',
    //   dataIndex : 'status',
    //   render    : (status: boolean) =>
    //   {
    //     return (
    //       <div className={'user-status center-container'}>
    //         {status ? <i style={{ color: '#198754', fontSize: 30 }} className="fas fa-toggle-on"></i> : <i style={{ color: '#eee', fontSize: 30 }} className="fas fa-toggle-off"></i>}
    //       </div>
    //     );
    //   },
    // },
    {
      title     : 'Department',
      key       : 'department',
      dataIndex : 'department',
      render    : (department: string) =>
      {
        return (
          <div className={'user-department center-container'}>
            <span>
              {department || '_'}
            </span>
          </div>
        );
      },
    },
    {
      title     : 'Position',
      key       : 'position',
      dataIndex : 'position',
      render    : (position: string) =>
      {
        return (
          <div className={'user-position center-container'}>
            <span>
              {position || '_'}
            </span>
          </div>
        );
      },
    },
    {
      title     : 'Action',
      key       : 'action',
      dataIndex : 'action',
      render    : (text: string, row: IUserOfList) =>
      {
        return (
          <div className="btn-group  center-container">
            <button
              onClick={() => onEditUser(row)}
              type="button" className="btn btn-outline-secondary">
              <i className="icofont-edit text-success" />
            </button>
            {/* <Popconfirm
              placement={'topLeft'}
              title="Are you sure to delete this user?"
              onConfirm={() => onDeleteUser(row)}
              okText="Delete"
              cancelText="Cancel"
            >
              <button
                type="button" className="btn btn-outline-secondary deleterow"><i className="icofont-ui-delete text-danger" /></button>
            </Popconfirm> */}
            {/* <button
              onClick={() => onTaskDetails(row)}
              type="button" className="btn btn-outline-secondary">
              <i className="far fa-eye"></i>
            </button> */}
          </div>
        );
      }
    }
  ];
    
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => ({
    loading: state.app.loading
  }));
    
  const [users, setUsers] = useState<IUserOfList[]>([]);
  const [currentUser, setCurrentUser] = useState<IUserOfList | undefined>();
  const [filter, setFilter] = useState<{ keyword?: string, status?: 'true' | 'false' | string}>({ status: '', keyword: '' });
  const _setFilter = debounce(setFilter, 200);
  const modalUser = useRef<RefModal>(null);
    
  useEffect(() => {
    fetchUsers();
  }, [filter]);
    
  const fetchUsers = async () =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({
      api: `${USER_LIST}?key=${filter.keyword}&status=${filter.status}`,
    });
    if (response.success)
    {
      setUsers(response.data);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };
    
  const onEditUser = (user: IUserOfList) =>
  {
    setCurrentUser(user);
    modalUser.current?.show();
  };
    
  const onCloseModal = (refresh: boolean) =>
  {
    if (refresh)
    {
      fetchUsers();
    }
    setCurrentUser(undefined);
    modalUser.current?.hide();
  };
    
  return (
    <>
      <div className="body d-flex py-lg-3 py-md-2 user-list">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">
                  {'Users'}
                </h3>
                <div className={'options-bar'}>
                  <div className={'user-filter'}>
                    <Search
                      placeholder="Type user name or email..."
                      className={'search-box'}
                      allowClear
                      onChange={(event) => _setFilter({ ...filter, keyword: event.target.value || '' })}
                      style={{ width: 300 }} />
                    <Select<string>
                      allowClear
                      placeholder="Status"
                      onChange={(value) => _setFilter({ ...filter, status: value || '' })}
                    >
                      <Option key={'active'} value={'true'} >{'Active'}</Option>
                      <Option key={'inactive'} value={'false'} >{'Inactive'}</Option>
                    </Select>
                    {/* <Switch defaultChecked onChange={(value) => _setFilter({ ...filter, status: `${value}` || '' })} /> */}
                  </div>
                  <div className="col-auto d-flex w-sm-100">
                    <button
                      onClick={() => modalUser.current?.show()}
                      type="button" className="btn btn-dark btn-set-user w-sm-100">
                      <i className="icofont-plus-circle me-2 fs-6" />
                      {'Add User'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> {/* Row end  */}
          <Table<IUserOfList>
            columns={columns}
            loading={loading}
            dataSource={users}
            className={'user-table'} />
        </div>
      </div>
      <ModalComponent onClose={() => setCurrentUser(undefined)} ref={modalUser}>
        <ModalUser
          user={currentUser}
          onClose={onCloseModal} />
      </ModalComponent>
    </>
  );
};

export default UserList;
