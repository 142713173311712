import React from 'react';
import { Empty } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { defaultAvatar } from '../../../../../global/constants';
import { ITaskGroup, ITaskListGroup } from '../../../../task/types';

interface ITaskListBoard
{
  data: ITaskListGroup | undefined
}

const TaskListBoard = (props: ITaskListBoard): JSX.Element =>
{
  const { data } = props;
  const renderTaskItems = (tasks: ITaskGroup | undefined) =>
  {
    if(!tasks){
      return (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />);
    }
      
    const taskColorClass = tasks.status === 'TO_DO' ?'light-primary-bg': tasks.status === 'IN_PROGRESS' ? 'light-orange-bg' : 'light-success-bg';
      
    return( <div className="">
      <div className="dd">
        {tasks.tasks.map(task => (
          <Link to={`/task/${task.id}`} className="dd-item" key={task.id}>
            <div className="dd-handle">
              <div className="task-info d-flex align-items-center justify-content-between">
                <h6 className={`py-1 px-2 rounded-1 d-inline-block fw-bold small-14 mb-0 ${taskColorClass}`}>
                  {task.name}
                </h6>
                <div className="task-priority d-flex flex-column align-items-center justify-content-center">
                  <div className="avatar-list avatar-list-stacked m-0">
                    <img className="avatar rounded-circle small-avt" src={task.assignee?.avatarUrl || defaultAvatar}/>
                  </div>
                  {/* <span className="badge bg-warning text-end mt-2">MEDIUM</span> */}
                </div>
              </div>
              <p className="py-2 mb-0">
                {task.description}
              </p>
              <div className="tikit-info row g-3 align-items-center">
                <div className="col-sm">
                  <ul className="d-flex list-unstyled align-items-center flex-wrap">
                    <li className="me-2">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-flag"></i>
                        <span className="ms-1">
                          {task.dueDate ? moment(task.dueDate).calendar() : 'No due date'}
                        </span>
                      </div>
                    </li>
                    <li className="me-2">
                      <div className="d-flex align-items-center">
                        <i className="far fa-comment-alt"></i>
                        <span className="ms-1">
                          {task.numOfComment}
                        </span>
                      </div>
                    </li>
                    <li className="me-2">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-paperclip"></i>
                        <span className="ms-1">
                          {task.attachments.length}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-tasks"></i>
                        <span className="ms-1">
                          {task.numOfSubtask}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-sm text-end">
                    <div className={`small text-truncate py-1 px-2 rounded-1 d-inline-block fw-bold small ${taskColorClass}`}> Box of Crayons </div>
                  </div> */}
              </div>
            </div>
            <div
              style={{ height: 4, width: '100%', borderRadius: '0 0 2px 2px' }}
              className={taskColorClass}
            />
          </Link>))}
      </div>
    </div>);
  };
    
  return (
    <div className="row taskboard g-3 py-xxl-4">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-4 mt-4">
        <h6 className="fw-bold py-3 mb-0">{`To do (${data?.todo?.tasks?.length || '0'})`}</h6>
        {renderTaskItems(data?.todo)}                
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-0 mt-sm-0 mt-0">
        <h6 className="fw-bold py-3 mb-0">{`In Progress (${data?.inProgress?.tasks?.length || '0'})`}</h6>
        {renderTaskItems(data?.inProgress)}     
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-0 mt-sm-0 mt-0">
        <h6 className="fw-bold py-3 mb-0">{`Completed (${data?.done?.tasks?.length || '0'})`}</h6>
        {renderTaskItems(data?.done)}     
      </div>
    </div>
            
  );
};
export default TaskListBoard;