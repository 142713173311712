import React from 'react';
import './index.scss';

interface ILoading {
  loading: boolean,
  type?: number,
  maskedColor?: string,
}

const LoadingComponent = ({ loading, type = 1, maskedColor = '#00000036' }: ILoading) =>
{
  return (
    <div style={{ backgroundColor: maskedColor }} className={`itask-loading ${loading && 'itask-loading-active'}`}>
      <div className={`loader${type}`}>Loading...</div>
    </div>
  );
};

const Loading = React.memo(LoadingComponent);
export default Loading;