export const API = {
  // local
//   DOMAIN  : 'https://localhost:5001',
  // build
  //   DOMAIN  : 'https://04cab31e04be.ngrok.io',
  // DOMAIN  : 'https://server.itask.link',
  DOMAIN  : 'https://itask.phongdaotao.com',
  APP_API : '/api'
};

// localhost
// export const GOOGLE_CLIENT_ID = '904844529130-bron67lftru4eqmkoap446mb4sh9toi5.apps.googleusercontent.com';
// build client
// export const GOOGLE_CLIENT_ID = '904844529130-75iii07u20ebe856dk4011r0cs3vvi7n.apps.googleusercontent.com';
// build itask.link
export const GOOGLE_CLIENT_ID = '792408060920-vcbbg8j7u9fcqvt393e4ctrrsnfuibi3.apps.googleusercontent.com';
// export const GOOGLE_CLIENT_ID = '904844529130-apqhr7f9ho8p5tm8u1qcvtilm1caq4v7.apps.googleusercontent.com';

export const TOKEN_COOKIE = 'token';

export const defaultAvatar = '../assets/images/profile_av.png';

export const taskStatus : {[key: string]: {name: string, color: string, icon: string}} = {
  TO_DO: {
    name  : 'To do',
    color : '#fa8c16',
    icon  : 'fas fa-plus-circle'
  },
  IN_PROGRESS: {
    name  : 'In progress',
    color : '#1890ff',
    icon  : 'fas fa-spinner'
  },
  DONE: {
    name  : 'Done',
    color : '#52c41a',
    icon  : 'far fa-check-circle'
  }
};