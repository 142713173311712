import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false
};

const appSlice = createSlice({
  name     : 'app',
  initialState,
  reducers : {
    enableLoading(state)
    {
      return {
        ...state,
        loading: true
      };
    },
    disableLoading(state)
    {
      return {
        ...state,
        loading: false
      };
    }
  }
});

export const { enableLoading, disableLoading } = appSlice.actions;

const appReducers = appSlice.reducer;

export default appReducers;