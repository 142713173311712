import { Interface } from 'readline';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IAction
{
  type: string,
  payload: any
}

export interface IHeaders
{
  'Content-Type': string,
  'Access-Control-Allow-Origin': string
}

export interface IRequestOptions
{
  method?: 'get' | 'post' | 'put' | 'delete',
  headers?: IHeaders,
  api?: string,
  url?: string,
  data?: any,
  showLog?: boolean,
}

export interface IResponse
{
  success: boolean,
  data?: any,
  message?: string
}

export const defaultHeaders = {
  'Content-Type'                : 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin' : '*',
};