/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { defaultAvatar } from '../../../../global/constants';
import { logoutServer } from '../../actions';


const Profile = (): JSX.Element =>
{
  const user = useAppSelector(state => state.user.information);
  const dispatch = useAppDispatch();

  const onLogout = (e: any) =>
  {
    e.preventDefault();
    dispatch(logoutServer());
  };
    
  return (
    <>
      {/* Body: Body */}
      <div className="body d-flex py-lg-3 py-md-2">
        <div className="container-xxl">
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card border-0 mb-4 no-bg">
                <div className="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
                  <h3 className=" fw-bold flex-fill mb-0">Profile</h3>
                </div>
              </div>
            </div>
          </div>{/* Row End */}
          <div className="row g-3">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="card teacher-card  mb-3">
                <div className="card-body  d-flex teacher-fulldeatil">
                  <div className="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 pe-0 text-center w220 mx-sm-0 mx-auto">
                    <a href="#">
                      <img src={user?.avatarUrl || defaultAvatar} className="avatar xl rounded-circle img-thumbnail shadow-sm" />
                    </a>
                  </div>
                  <div className="teacher-info border-start ps-xl-4 ps-md-3 ps-sm-4 ps-4 w-100">
                    <h6 className="mb-0 mt-2  fw-bold d-block fs-6">
                      {user.name}
                    </h6>
                    <div className="row g-2 pt-2">
                      <div className="col-xl-5">
                        <div className="d-flex align-items-center">
                          <i className="icofont-email" />
                          <span className="ms-2 small">{user.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row g-2 pt-2">
                      <div className="col-xl-5">
                        <div className="d-flex align-items-center">
                          <i className="icofont-user-suited" />
                          <span className="ms-2 small">{user.position || '_'}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row g-2 pt-2">
                      <div className="col-xl-5">
                        <div className="d-flex align-items-center">
                          <i className="icofont-building-alt" />
                          <span className="ms-2 small">{user.department || '_'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="d-flex py-2 project-tab flex-wrap w-sm-100 justify-content-center">
                <button type="button" className="btn btn-danger w-sm-100" onClick={onLogout}>
                  <i className="icofont-logout fs-6 me-3" />
                  {'Log out'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  
  );
};

export default Profile;
