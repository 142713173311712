import React, { useEffect, useState } from 'react';
import
{
  Select,
  Form,
  Input,
  DatePicker,
  FormInstance,
  Upload,
  Button,
} from 'antd';
import ApiUtils from '../../../global/services';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { disableLoading, enableLoading } from '../../../global/slice';
import { notify } from '../../../global/helpers';
import { IUserItem } from '../../user/types';
import { IProjectItem } from '../../project/types';
import { PROJECT_JOINED, PROJECT_MEMBERS } from '../../project/api';
import { ICreateTask, ITaskItem, ITaskModal } from '../types';
import { TASK_CREATE, TASK_LIST_BY_PROJECT_ID, TASK_UPDATE } from '../api';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

const { TextArea } = Input;
const { Option } = Select;

const ModalTask = (props: ITaskModal): JSX.Element =>
{
  const user = useAppSelector(state => state.user.information);
  const dispatch = useAppDispatch();
    
  const formRef = React.createRef<FormInstance<ICreateTask>>();

  const [projectId, setProjectId] = useState(props.projectId);
  const [userList, setUserList] = useState<IUserItem[]>([]);
  const [projectList, setProjectList] = useState<IProjectItem[]>([]);
  const [taskList, setTaskList] = useState<ITaskItem[]>([]);
  const [fileList, setFileList] = useState< UploadFile<any>[]>([]);
  const [previewImage, setPreviewImage] = useState({
    previewVisible : false,
    previewImage   : '',
    previewTitle   : '',
  });
    
  const defaultValues : ICreateTask = props.task?.id ? {
    name        : props.task.name,
    description : props.task.description,
    projectId   : props.task.projectId,
    parentId    : props.task.parentId,
    dueDate     : props.task.dueDate ? moment(props.task.dueDate) : '',
    assignee    : props.task.assignee.id,
    status      : props.task.status || 'IN_PROGRESS',
    fileBase64  : [],
    
  } : {
    name        : '',
    description : '',
    projectId   : projectId || '',
    parentId    : null,
    dueDate     : '',
    assignee    : '',
    status      : 'TO_DO',
    fileBase64  : [],
  };
       
  useEffect(() => {
    fetchProjectList();
  }, [user.id]);
    
  useEffect(() =>
  {
    if (projectId && projectId !== '')
    {
      fetchMembers();
      fetchTaskList();
      
    } 
    formRef.current?.resetFields(['parentId', 'assignee']);
  }, [projectId]);
    
  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
    
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage({
      previewImage   : file.url || file.preview,
      previewVisible : true,
      previewTitle   : file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
    
  const handleChange = (info: any) =>
  {
    console.log(info);
      
    // let fileList = [...info.fileList];
    // // Accept 5 files only
    // fileList = fileList.slice(-5);
    // fileList.forEach(function (file, index) {
    //   const reader = new FileReader();s
    //   reader.onload = (e:any) => {
    //     file.base64 = e.target.result;
    //   };
    //   reader.readAsDataURL(file.originFileObj);
    // });
    // console.log('fileList: ', fileList);
  };
  const handleCancel = () => setPreviewImage({ ...previewImage, previewVisible: false });
    
  const configUpload ={
    onRemove: (file: UploadFile<any>) =>
    {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) =>
    {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  const fetchMembers = async () =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({ api: `${PROJECT_MEMBERS}/${projectId}` });
      
    if (response.success)
    {
      setUserList(response.data);
    } else
    {
      notify({ type: 'error', message: response.message || 'Cannot load user list' });
    }
    dispatch(disableLoading());
  };
    
  const fetchProjectList = async () =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({
      api: `${ PROJECT_JOINED }/${ user.id }`,
    });
      
    if (response.success)
    {
      setProjectList(response.data);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message, title: 'Project List' });  
      dispatch(disableLoading());
    }
  };
    
  const fetchTaskList = async () =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({
      api: `${ TASK_LIST_BY_PROJECT_ID }/${ projectId }`,
    });
    console.log('response: ', response);
    if (response.success)
    {
      setTaskList(response.data);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message, title: 'Task List' });  
      dispatch(disableLoading());
    }
  };
    
  const onCreateProject = async (data: ICreateTask) =>
  {
    
    //   const file = fileList.map(element =>
    //   {
    //     await toBase64(element).catch(e => Error(e));
    //   })
    // const result = await toBase64(fileList[0]).catch(e => Error(e));
    console.log('data:', data);
    dispatch(enableLoading());
    const response = await ApiUtils.request({ api: TASK_CREATE, method: 'post', data });
    if (response.success)
    {
      notify({ type: 'success', message: 'Successfully created new task' });
      props.onClose?.(true);
    } else
    {
      notify({ type: 'error', message: response.message });
    }
    dispatch(disableLoading());
  };
    
  const onUpdateProject = async (id: string, data: ICreateTask) =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({ api: `${TASK_UPDATE}/${id}`, method: 'post', data });
    if (response.success)
    {
      notify({ type: 'success', message: 'Task update successful' });
      props.onClose?.(true);
    } else
    {
      notify({ type: 'error', message: response.message });
    }
    dispatch(disableLoading());
  };
    

  const onFinish = async (values: ICreateTask) =>
  {
    const files: any = [];
    // await fileList.forEach(async (element) =>
    // {
    //   const result = await toBase64(element).catch(e => Error(e));
    //   files.push(result);
    // });
      
    for (const item of fileList)
    {
      const result = await toBase64(item).catch(e => Error(e));
      files.push(result);
    }
      
    console.log('files: ', files);
      
    const data: ICreateTask = {
      name        : values.name,
      description : values.description,
      assignee    : values.assignee,
      dueDate     : values.dueDate ? moment(values.dueDate).format('DD-MM-YYYY') : null,
      parentId    : values.parentId,
      projectId   : values.projectId,
      status      : values.status,
      fileBase64  : files,
    }; 
    if (props.task?.id)
    {
      onUpdateProject(props.task.id, data);
    } else
    {
      onCreateProject(data);
    }
  };
  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

    
    
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
    
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  console.log(fileList);
    
  return (
    <Form
      ref={formRef}
      name="createTask"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={defaultValues}
    >
      <div className="">
        <h5>{ defaultValues.name ? 'Update Task' : 'Create Task'}</h5>
        <div className="mb-3">
          <label className="form-label">Task Name</label>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please input your task name!' }]}
          >
            <Input
              placeholder={'Enter task name'}
            />
          </Form.Item>
        </div>
        <div className="mb-3">
          <div className="col-sm-12">
            <label htmlFor="formFileMultipleone" className="form-label">Project</label>
            <Form.Item
              name="projectId"
              rules={[{ required: true, message: 'Please select project!' }]}
            >
              <Select
                showSearch
                onChange={(value: string) => setProjectId(value || '') }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerASCII().indexOf(input.toLowerCase()) >= 0
                }
                disabled={props.projectId ? true : false}
                placeholder="Select project"
              >
                {projectList.map((urs) => (<Option key={urs.id} value={urs.id} >{urs.name}</Option>))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="mb-3">
          <div className="col-sm-12">
            <label htmlFor="formFileMultipleone" className="form-label">Parent task</label>
            <Form.Item
              name="parentId"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerASCII().indexOf(input.toLowerCase()) >= 0
                }
                disabled={!projectId && projectId === '' ? true : false}
                placeholder="Select parent task"
              >
                {taskList.map((task) =>
                {
                  let isRender = true;
                  if (props.task && task.id === props.task.id)
                  {
                    isRender = false;
                  }
                  return isRender && (<Option key={task.id} value={task.id} >{task.name}</Option>);
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="mb-3">
          <div className="col-sm-12">
            <label htmlFor="formFileMultipleone" className="form-label">Assignee</label>
            <Form.Item
              name="assignee"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerASCII().indexOf(input.toLowerCase()) >= 0
                }
                disabled={!projectId && projectId === '' ? true : false}
                placeholder="Select assignee"
              >
                {userList.map((urs) => (<Option key={urs.id} value={urs.id} >{urs.name}</Option>))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="mb-3">
          <div className="col-sm-12">
            <label htmlFor="formFileMultipleone" className="form-label">Assignee</label>
            <Form.Item
              name="status"
              rules={[{ required: true, message: 'Please select your task status!' }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerASCII().indexOf(input.toLowerCase()) >= 0
                }
                disabled={!projectId && projectId === '' ? true : false}
                placeholder="Select status"
              >
                <Option key={'TO_DO'} value={'TO_DO'} >{'To do'}</Option>
                <Option key={'IN_PROGRESS'} value={'IN_PROGRESS'} >{'In progress'}</Option>
                <Option key={'DONE'} value={'DONE'} >{'Done'}</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="datepickerded" className="form-label">Due Date</label>
          <Form.Item
            name="dueDate"
          >
            <DatePicker
              placeholder={'Select task due date'}
              className={'form-control'}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea78" className="form-label">Description</label>
          <Form.Item
            name="description"
          >
            <TextArea
              placeholder={'Enter task description'}
              rows={3}
            />
          </Form.Item>
        </div>
        <div className="mb-3">
          <label className="form-label">Attachment</label>
          <Form.Item
            name="fileBase64"
          >
            <Upload
              listType="picture-card"
              onPreview={handlePreview}
              onChange={handleChange}
              multiple
              {...configUpload}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
           
          </Form.Item>
        </div>
      </div>
      <div className="modal-footer">
        <Form.Item >
          <button type="submit" className="btn btn-primary">
            {props.task?.id ? 'Update' : 'Create'}
          </button>
        </Form.Item>
        
      </div>
      <Modal
        visible={previewImage.previewVisible}
        title={previewImage.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage.previewImage} />
      </Modal>
    </Form>
  ); 
};

export default ModalTask;
