import React from 'react';
import { IAppLayout } from './types';
import AppHeader from './AppHeader';
import AppSideBar from './AppSideBar';
import Loading from '../components/loading';
import { useAppSelector } from '../app/hooks';



const AppLayout = (props: IAppLayout): JSX.Element =>
{
  const loading = useAppSelector(state => state.app.loading);
    
  return (
    <div id="mytask-layout" className="theme-indigo">
      {/* sidebar */}
      <AppSideBar/>
      {/* main body area */}
      <div className="main px-lg-4 px-md-4 itask-main-container">
        {/* Body: Header */}
        <AppHeader/>
        <div className={'itask-body'}>
          {props.children}
        </div>
      </div>
      <Loading loading={loading} />  
    </div>

  );
};

export default AppLayout;
