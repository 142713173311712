/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { notify } from '../../../../global/helpers';
import ApiUtils from '../../../../global/services';
import { disableLoading, enableLoading } from '../../../../global/slice';
import { TASK_LIST_BY_USER } from '../../api';
import { ITaskItem, ITaskListGroup } from '../../types';
import TaskGrid from './components/TaskGrid';
import { debounce } from '@material-ui/core';
import TaskListBoard from '../../../project/pages/project-details/components/TaskListBoard';
import Search from 'antd/lib/input/Search';

const MyTasks = (): JSX.Element =>
{
  const dispatch = useAppDispatch();
  const [tasks, setTasks] = useState<ITaskItem[]>([]);
  const [filter, setFilter] = useState<{ keyword?: string, status?: string }>({ status: '', keyword: '' });
  const _setFilter = debounce(setFilter, 200);
  const [viewGrid, setViewGrid] = useState(true);
  const [taskList, setTaskList] = useState<ITaskListGroup>({});
    
  const { user } = useAppSelector(state => ({
    user: state.user.information,
  }));
    
  useEffect(() => {
    fetchTasks();
  }, [user.id, filter]);
        
  const fetchTasks = async () =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({
      api: `${ TASK_LIST_BY_USER }/${ user.id }?keyword=${filter.keyword}&status=${filter.status}`,
    });
    if (response.success)
    {
      setTasks(response.data);
      const anotherList = formatData(response.data);
      setTaskList(anotherList);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };
    
  const _onSetFilter = (e:{keyword?: string, status?: string}) =>
  {
    _setFilter({ ...filter, ...e });
  };
    
  return (
    <div>
      <div className={'clickable-area'} style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={()=>setViewGrid(!viewGrid)}>
        <i className={viewGrid ? 'icofont-listing-box':'icofont-leaflet'} style={{ fontSize: 30 }}></i>
      </div>
      {/* <Search
        placeholder="Input search text"
        className={'search-box'}
        allowClear
        onChange={()=>console.log('on filter')}
        style={{ width: 300 }} /> */}
      {viewGrid ? <TaskGrid tasks={tasks} onSetFilter={_onSetFilter} onFetchTask={fetchTasks} title={'My Task'}/>
        : (<div><h3 className="fw-bold mb-0">My Task</h3>
          <TaskListBoard data={ taskList}/></div>)}
    </div>
   
  );
};

export default MyTasks;


const formatData = (data:ITaskItem[] ) =>
{
  const taskList: ITaskListGroup = { };
  const todo = data.filter(element => element.status === 'TO_DO');
  const inProgress = data.filter(element => element.status === 'IN_PROGRESS');
  const done = data.filter(element => element.status === 'DONE');
    
  taskList.todo = todo && { status: 'TO_DO', tasks: todo };
  taskList.inProgress = inProgress && { status: 'IN_PROGRESS', tasks: inProgress };
  taskList.done = done && { status: 'DONE', tasks: done };

  return taskList;
};