import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { Modal, Button } from 'antd';
import { IModal, RefModal } from './types';


const ModalComponent = forwardRef(({ children, footer, title, hideFooter = true, onClose } : IModal, ref: Ref<RefModal>) : JSX.Element => {
    
  useImperativeHandle(ref, () => ({
    show,
    hide
  }));
    
  const [visible, setVisible] = useState<boolean>(false);
    
  const show = () =>
  {
    setVisible(true);
  };
    
  const hide = () =>
  {
    setVisible(false);
    onClose?.();
  };
    

  const renderFooter = () =>
  {
    if (hideFooter)
      return null;
    if (footer)
      return footer;
    return [
      <Button key="submit" type="primary" onClick={hide}>
        {'Submit'}
      </Button>,
      <Button
        key="link"
        type="default"
        onClick={hide}
      >
        {'Cancel'}
      </Button>,
    ];
  };
    
  return (
    <Modal
      visible={visible}
      title={title || ''}
      onCancel={hide}
      maskClosable={false}
      destroyOnClose={true}
      footer={renderFooter()}
    >
      {children}
    </Modal>
  );
});

ModalComponent.displayName = 'ModalComponent';

export default ModalComponent;
