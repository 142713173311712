/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { IRequestOptions, IResponse, defaultHeaders } from '../app/types';
import { API } from './constants';

 
export default class ApiUtils
{
    
  static defaultHeaders = defaultHeaders
    
  static updateHeaders = (headers: any) : void => {
    ApiUtils.defaultHeaders = {
      ...ApiUtils.defaultHeaders,
      ...headers
    };
  }

  static request = async ({
    method = 'get', headers = ApiUtils.defaultHeaders, api, url, data, showLog = false }: IRequestOptions): Promise<IResponse> =>
  {

    let requestUrl = '';
    if (url && url !== '')
    {
      requestUrl = url;
    } else
    {
      requestUrl = API.DOMAIN + API.APP_API + api;
    }
    const options = {
      method,
      headers: {
        ...ApiUtils.defaultHeaders,
        ...headers
      },
      url: requestUrl,
      data
    };
    console.log('data: ', JSON.stringify(data));
      
    let result : IResponse = { success: false };
    await axios(options)
      .then((response) =>
      {
        if (showLog)
        {
          console.log('=================');
          console.log('options: ', options);
          console.log('response: ', response.data );
          console.log('=================');
        }
        if (response.data?.success)
        {
          result = {
            success : true,
            data    : response.data.results
          };   
        } else
        {
          
          result = {
            success : false,
            message : response.data.message || ''
          };
        }
      })
      .catch((error) =>
      {
        if (showLog)
        {
          console.log('error: ', error);
        }
        result = {
          success : false,
          message : error.message
        };
      });

    console.log('result: ', result);
    return result;
  };
}
