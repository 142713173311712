import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import ModalComponent from '../../../../components/modal';
import { RefModal } from '../../../../components/modal/types';
import { defaultAvatar } from '../../../../global/constants';
import { notify } from '../../../../global/helpers';
import ApiUtils from '../../../../global/services';
import { disableLoading, enableLoading } from '../../../../global/slice';
import { ITaskItem, ITaskListGroup } from '../../../task/types';
import { PROJECT_DETAILS } from '../../api';
import { IProjectDetails, IProjectDetailsResponse, IProjectItem } from '../../types';
import ModalTask from '../../../task/components/ModalTask';
import ModalProject from '../../components/ModalProject';
import TaskGrid from '../../../task/pages/my-task/components/TaskGrid';
import TaskListBoard from './components/TaskListBoard';

const { Panel } = Collapse;

const ProjectDetails = (): JSX.Element =>
{
  const params = useParams<{id: string}>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [data, setData] = useState<IProjectDetails | null>();
  const [dataItem, setDataItem] = useState<IProjectItem>();
  const [viewGrid, setViewGrid] = useState(false);
  const [dataGrid, setDataGrid] = useState<ITaskItem[]>([]);
    
  const modalTask = useRef<RefModal>(null);
  const modalProject = useRef<RefModal>(null);
    
  useEffect(() => {
    fetchData();
  }, [params.id]);
    
  const fetchData = async () =>
  {
    dispatch(enableLoading());
    const response: {success: boolean, data?: IProjectDetailsResponse, message?: string} = await ApiUtils.request({
      api: `${PROJECT_DETAILS}/${params.id}`,
    });
      
    if (response.success && response.data)
    {
      setDataItem(response.data);
      const dataProceeded = processData(response.data);
      const anotherList: ITaskItem[] =[];
      response.data.taskList.map((list) =>
      {
        Array.prototype.push.apply(anotherList, list.tasks);
      });
      setDataGrid(anotherList);
      console.log('dataProceeded: ', dataProceeded);
      setData(dataProceeded);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };
    
  
  const onOpenModalEdit = () =>
  {
    modalProject.current?.show();
  };
    
  const onCloseModal = (refresh: boolean) =>
  {
    if (refresh)
    {
      fetchData();
    }
    modalTask.current?.hide();
    modalProject.current?.hide();
  };
    
  const renderActivities = () =>
  {
    return (
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
        <div className="card">
          <div className="card-header py-3">
            <h6 className="mb-0 fw-bold ">Recent Activity</h6>
          </div>
          <div className="card-body mem-list">
            {data?.activities.map(activity =>
            {
              const activityColor = activity.activityType === 'REMOVE_FROM_PROJECT' || activity.activityType === 'UNASSIGNED_TASK' ? 'ti-danger' : activity.activityType === 'CREATE_TASK' ? 'ti-success' : 'ti-info';
              return (
                <div key={activity.id} className={`timeline-item ${activityColor} border-bottom ms-2`}>
                  <div className="d-flex">
                    <span className="avatar d-flex justify-content-center align-items-center rounded-circle light-success-bg">
                      <img className="avatar rounded-circle small-avt" src={activity.user?.avatarUrl || defaultAvatar}/>
                    </span>
                    <div className="flex-fill ms-3">
                      <div className="mb-1">
                        {activity.activityContent}
                      </div>
                      <span className="d-flex text-muted">
                        {moment(activity.createdAt).calendar()}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> 
      </div>
    );
  };
    
  const renderMembers = () =>
  {
    return (
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
        <div className="card">
          <div className="card-header py-3">
            <h6 className="mb-0 fw-bold ">Members</h6>
          </div>
          <div className="card-body">
            <div className="flex-grow-1 mem-list">
              {data?.userJoined?.map((user) => (
                <div key={user.id} className="py-2 d-flex align-items-center border-bottom">
                  <div className="d-flex ms-2 align-items-center flex-fill">
                    <img src={user?.avatarUrl || defaultAvatar} className="avatar lg rounded-circle img-thumbnail" alt="avatar" />
                    <div className="d-flex flex-column ps-2">
                      <h6 className="fw-bold mb-0">
                        {user.name}
                      </h6>
                      <span className="small text-muted">
                        {user.role || '_'} 
                      </span>
                    </div>
                  </div>
                  {/* <button type="button" className="btn light-danger-bg text-end">Remove</button> */}
                </div>
              ))}
            </div>
          </div>
        </div> 
      </div>
    );
  };
    
  const renderTaskProgress = () =>
  {
    return (
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
        <div className="card">
          <div className="card-header py-3">
            <h6 className="mb-0 fw-bold ">Task Progress</h6>
          </div>
          <div className="card-body mem-list">
            <div className="progress-count mb-4">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <h6 className="mb-0 fw-bold d-flex align-items-center">To do</h6>
                {`${data?.taskList?.todo?.tasks.length || 0}/${data?.numOfTotalTask}`}
              </div>
              <div className="progress" style={{ height: 10 }}>
                <div className="progress-bar light-primary-bg" role="progressbar" style={{ width: `${(data?.taskList.todo ? data?.taskList.todo.tasks.length/data.numOfTotalTask : 0) * 100}%` }} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
              </div>
            </div>
            <div className="progress-count mb-4">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <h6 className="mb-0 fw-bold d-flex align-items-center">In progress</h6>
                {`${data?.taskList?.inProgress?.tasks.length || 0}/${data?.numOfTotalTask}`}
              </div>
              <div className="progress" style={{ height: 10 }}>
                <div className="progress-bar light-orange-bg" role="progressbar" style={{ width: `${(data?.taskList.inProgress ? data?.taskList.inProgress.tasks.length/data.numOfTotalTask : 0) * 100}%` }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} />
              </div>
            </div>
            <div className="progress-count mb-4">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <h6 className="mb-0 fw-bold d-flex align-items-center">Done</h6>
                {`${data?.taskList?.done?.tasks.length || 0}/${data?.numOfTotalTask}`}
              </div>
              <div className="progress" style={{ height: 10 }}>
                <div className="progress-bar light-success-bg" role="progressbar" style={{ width: `${(data?.taskList.done ? data?.taskList.done.tasks.length/data.numOfTotalTask : 0) * 100}%` }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="body d-flex py-lg-3 py-md-2">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <div className="col-auto d-flex align-items-center">
                  <div onClick={()=> history.goBack()} style={{ marginRight: 15 }}>
                    <i className="icofont-arrow-left" style={{ fontSize: 30 }}></i>
                  </div>
                  <h5 className="mb-0">{'[PROJECT]'}</h5>
                  <h3 className="fw-bold mb-0">
                    {data?.name}
                  </h3>          
                </div>
              
                <div className="col-auto d-flex w-sm-100">
                  <button
                    type="button"
                    className="btn btn-success btn-set-task w-sm-100"
                    style={{ marginLeft: 10 }}
                    onClick={onOpenModalEdit}
                  >
                    <i className="icofont-edit me-2 fs-6" />
                    {'Edit Project'}    
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span>{ data?.description}</span>
          </div>
          <div className="row clearfix  g-3">
            <div className="col-lg-12 col-md-12 flex-column">
              <Collapse
                expandIcon={(a) =>
                {
                  if (a.isActive)
                  {
                    return (
                      <i className="fas fa-chevron-down expand-icon-itask"></i>
                    );
                  }
                  return (
                    <i className="fas fa-chevron-right expand-icon-itask"></i>
                  );
                }}
                expandIconPosition={'right'}
                defaultActiveKey={['about-project', 'all-task']}
                ghost
                bordered={false}
                onChange={(value) => console.log('value: ', value)}
              >
                <Panel header={(
                  <h4 className="text-primary">
                    <i className="icofont-info-circle"></i>
                    {' Information'}</h4>
                )}
                key={'about-project'}>
                  <div className="row g-3 row-deck">
                    {renderTaskProgress()}
                    {renderActivities()}
                    {renderMembers()}
                  </div>
                </Panel>
                <Panel header={(
                  <h4 className="text-primary">
                    <i className="icofont-tasks"></i>
                    {' All Task'}</h4>
                )}
                key={'all-task'}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={()=>setViewGrid(!viewGrid)}>
                    <i className={viewGrid ? 'icofont-listing-box':'icofont-leaflet'} style={{ fontSize: 30 }}></i>
                  </div>
                  {viewGrid ? <TaskGrid tasks={dataGrid} projectId={data?.id} onSetFilter={()=>{console.log();}} onFetchTask={()=>{console.log();}} />
                    : <TaskListBoard data={data?.taskList }/>}
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent ref={modalTask}>
        <ModalTask
          projectId={data?.id}
          onClose={onCloseModal} />
      </ModalComponent>
      <ModalComponent ref={modalProject}>
        <ModalProject project={dataItem} onClose={onCloseModal}/>
      </ModalComponent>
    </>

  );
};

export default ProjectDetails;

const processData = (data: IProjectDetailsResponse) : IProjectDetails =>
{
  const taskList: ITaskListGroup = { };
      
  taskList.todo = data.taskList.find(element => element.status === 'TO_DO');
  taskList.inProgress = data.taskList.find(element => element.status === 'IN_PROGRESS');
  taskList.done = data.taskList.find(element => element.status === 'DONE');
      
  const result: IProjectDetails = {
    ...data,
    taskList
  };
      
  return result;
};