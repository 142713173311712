/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { taskStatus } from '../../../../global/constants';
import { notify } from '../../../../global/helpers';
import ApiUtils from '../../../../global/services';
import { disableLoading, enableLoading } from '../../../../global/slice';
import { COMMENT_CREATE, TASK_DETAILS } from '../../api';
import { ITask } from '../../types';
import ModalComponent from '../../../../components/modal';
import ModalTask from '../../components/ModalTask';
import { RefModal } from '../../../../components/modal/types';
import SubTask from './components/SubTask';
import InformationTask from './components/InformationTask';

const { Panel } = Collapse;

const TaskDetails = (): JSX.Element =>
{ 
  const params = useParams<{ id: string }>();
  const history = useHistory();
    
  const dispatch = useAppDispatch();
    
  const modalTask = useRef<RefModal>(null);
  const [task, setTask] = useState<ITask>();
  const [status, setStatus] = useState({ name: '', color: '', icon: '' });
  let comment = '';
    
  useEffect(() =>
  {
    fetchTask();
  }, [params.id]);

  const fetchTask = async () =>
  {
    dispatch(enableLoading());
    const response = await ApiUtils.request({
      api: `${ TASK_DETAILS }/${ params.id }`,
    });
      
    if (response.success)
    {
      setStatus(taskStatus[response.data?.status]);
      console.log('status: ', status);
      setTask(response.data);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };
    
  const onPostComment = async () =>
  {
    if (comment !== '' && comment.length > 0)
    {
      dispatch(enableLoading());
      const response = await ApiUtils.request({
        api    : `${ COMMENT_CREATE }`,
        method : 'post',
        data   : {
          taskId  : task?.id,
          content : comment
        }
      });
          
      if (response.success)
      {
        comment = '';
        await fetchTask();
        dispatch(disableLoading());
      } else
      {
        notify({ type: 'error', message: response.message });  
        dispatch(disableLoading());
      }
    } else
    {
      notify({ type: 'error', message: 'Please enter comment content', title: 'Comment' });
    }
  };
    
  const onChangeComment = (e: string) =>
  {
    comment = e;
  };
    
  const onCloseModal = (refresh: boolean) =>
  {
    if (refresh)
    {
      fetchTask();
    }
    modalTask.current?.hide();
  };

  return (
    <>
      <div className="body d-flex py-lg-3 py-md-2">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <div className="col-auto d-flex align-items-center">
                  <div onClick={()=> history.goBack()} style={{ marginRight: 15 }}>
                    <i className="icofont-arrow-left" style={{ fontSize: 30 }}></i>
                  </div>
                  <h5 className="mb-0">{'[TASK]'}</h5>
                  <h3 className="fw-bold mb-0">
                    {task?.name}
                  </h3>
                </div>
                <div className="col-auto d-flex w-sm-100">
                  <button
                    onClick={() => modalTask.current?.show()}
                    type="button" className="btn btn-dark btn-set-task w-sm-100">
                    <i className="fas fa-edit"></i>
                    {'Edit Task'}
                  </button>
                </div>
              </div>
              
            </div>
          </div>
          <div>
            <span>{ task?.description}</span>
          </div>
          <Collapse
            expandIcon={(a) =>
            {
              if (a.isActive)
              {
                return (
                  <i className="fas fa-chevron-down expand-icon-itask"></i>
                );
              }
              return (
                <i className="fas fa-chevron-right expand-icon-itask"></i>
              );
            }}
            expandIconPosition={'right'}
            defaultActiveKey={['about-project']}
            ghost
            bordered={false}
            onChange={(value) => console.log('value: ', value)}
          >
            <Panel header={(
              <h4 className="text-primary">
                <i className="icofont-info-circle"></i>
                {' Information'}</h4>
            )}
            key={'about-project'}>
              <InformationTask task={task}
                status={status}
                onPostComment={onPostComment}
                onChangeComment={onChangeComment} />
            </Panel>
            <Panel header={(
              <h4 className="text-primary">
                <i className="icofont-tasks"></i>
                {' SubTasks'}</h4>
            )}
            key={'sub-task'}>
              <SubTask task={task}/>
            </Panel>
          </Collapse>
        </div>
      </div>
      <ModalComponent ref={modalTask}>
        <ModalTask
          projectId={task?.projectId}
          task={task}
          onClose={onCloseModal} />
      </ModalComponent>
    </>
  );
};

export default TaskDetails;
