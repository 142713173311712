import { Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { defaultAvatar, taskStatus } from '../../../../../global/constants';
import { ITask } from '../../../types';

interface ISubTask
{
  task: ITask | undefined;
}

const SubTask = (props: ISubTask): JSX.Element =>
{
  const { task } = props;
  return (
    <div className="col-lg-12 col-md-12">
      {task?.subTask.map(task =>
      {
        const statusItem = taskStatus[task.status];
        return (
          <div className="dd-item" key={task.id}>
            <div className="dd-handle">
              <div className="task-info d-flex align-items-center justify-content-between">
                <h6 style={{ color: statusItem.color }} className={'py-1 px-2 rounded-1 d-inline-block fw-bold small-14 mb-0'}>
                  {task?.name}
                </h6>
                <div className="task-priority d-flex flex-column align-items-center justify-content-center">
                  <div className="avatar-list avatar-list-stacked m-0">
                    <img className="avatar rounded-circle small-avt" src={task.assignee?.avatarUrl || defaultAvatar}/>
                  </div>
                </div>
              </div>
              <p className="py-2 mb-0">
                {task.description}
              </p>
              <div className="tikit-info row g-3 align-items-center">
                <div className="col-sm bottom-subtask">
                  <ul className="d-flex list-unstyled align-items-center flex-wrap">
                    <li className="me-2">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-flag"></i>
                        <span className="ms-1">
                          {task.dueDate ? moment(task.dueDate).calendar() : 'No due date'}
                        </span>
                      </div>
                    </li>
                    <li className="me-2">
                      <div className="d-flex align-items-center">
                        <i className="far fa-comment-alt"></i>
                        <span className="ms-1">
                          {task.numOfComment}
                        </span>
                      </div>
                    </li>
                    <li className="me-2">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-paperclip"></i>
                        <span className="ms-1">
                          {task.numOfAttachment}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-tasks"></i>
                        <span className="ms-1">
                          {task.numOfSubtask}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <Tag icon={<i className={statusItem.icon}></i>} color={statusItem.color}>
                    {statusItem?.name}
                  </Tag>
                </div>
              </div>
            </div>
            <div style={{ height: 4, width: '100%', borderRadius: '0 0 2px 2px', borderColor: statusItem.color }} />
          </div>);
      } )}
    </div>   
  );
};

export default SubTask;