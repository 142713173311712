import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { viewAllProject, viewStarredProject } from '../../slice';
import ModalComponent from '../../../../components/modal';
import { RefModal } from '../../../../components/modal/types';
import ModalProject from '../../components/ModalProject';
import { IProjectItem } from '../../types';
import ProjectItem from '../../components/ProjectItem';
import { disableLoading, enableLoading } from '../../../../global/slice';
import ApiUtils from '../../../../global/services';
import { PROJECT_DELETE, PROJECT_JOINED } from '../../api';
import { notify } from '../../../../global/helpers';
import { useMemo } from 'react';

const ProjectList = (): JSX.Element =>
{
    
  const { viewMode, user } = useAppSelector(state => ({
    viewMode : state.project.viewMode,
    user     : state.user.information
  }));
  const dispatch = useAppDispatch();
    
  const [projectList, setProjectList] = useState<IProjectItem[]>([]);
  const [dataItem, setDataItem] = useState<IProjectItem>();
    
  const modalProject = useRef<RefModal>(null);
    
  useEffect(() => {
    fetchData(viewMode);
  }, [viewMode]);
    
  const fetchData = async (viewMode: 'all' | 'starred') =>
  {
    dispatch(enableLoading());
    // const apiRequest = viewMode === 'all' ? PROJECT_LIST : PROJECT_LIST;
      
    const response = await ApiUtils.request({
      api: `${ PROJECT_JOINED }/${ user.id }`,
    });
      
    if (response.success)
    {
      setProjectList(response.data);
      dispatch(disableLoading());
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };

    
  const onChangeViewMode = (viewMode: 'all' | 'starred') => () =>
  {
    if (viewMode === 'all')
    {
      dispatch(viewAllProject());
    } else
    {
      dispatch(viewStarredProject());
    }
  };

  const onOpenModalEdit = (project: IProjectItem) =>
  {
    setDataItem(project);
    modalProject.current?.show();
  };

  const onOpenModalCreate = () =>
  {
    setDataItem(undefined);
    modalProject.current?.show();
  };
    
  const onCloseModal = (refresh: boolean) =>
  {
    if (refresh)
    {
      fetchData(viewMode);
    }
    modalProject.current?.hide();
  };

  const onDeleteProject = async (project: IProjectItem) =>
  {
    dispatch(enableLoading());
      
    const response = await ApiUtils.request({
      api    : `${ PROJECT_DELETE }/${ project.id }`,
      method : 'delete'
    });
    console.log('response: ', response);
    if (response.success)
    {
      notify({ type: 'success', message: 'Delete project successfully' });  
      dispatch(disableLoading());
      fetchData(viewMode);
    } else
    {
      notify({ type: 'error', message: response.message });  
      dispatch(disableLoading());
    }
  };

  
  const renderHeader = () =>
  {
    return (
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h3 className="fw-bold py-3 mb-0">Projects</h3>
            <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
              {/* <div className="input-group flex-nowrap input-group-lg">
                <button type="button" className="input-group-text" id="addon-wrapping"><i className="fa fa-search" /></button>
                <input type="search" className="form-control" placeholder="Search" aria-label="search" aria-describedby="addon-wrapping" />
                        </div> */}
              {/* <button type="button" className="input-group-text" onClick={onOpenModalCreate}><i className="fa fa-search" /></button>
              <input type="search" className="form-control" placeholder="Search" aria-label="search" aria-describedby="addon-wrapping" /> */}
              <button type="button" className="btn btn-dark w-sm-100" onClick={onOpenModalCreate}>
                <i className="icofont-plus-circle me-2 fs-6" />
                {'Create Project'}
              </button>
              <ul className="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                <li className="nav-item">
                  <span onClick={onChangeViewMode('all')} className={`clickable-area nav-link ${ viewMode === 'all' && 'active'}`}>
                    {'All'}
                  </span>
                </li>
                <li className="nav-item">
                  <span onClick={onChangeViewMode('starred')} className={`clickable-area nav-link ${ viewMode === 'starred' && 'active'}`}>
                    {'Favorite'}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
    
  const renderProjectList = () =>
  {
    return projectList?.map((project) =>
    {
      return (
        <ProjectItem canEdit={true} onEditProject={onOpenModalEdit} onDeleteProject={onDeleteProject} key={project.id} {...project}/>
      );
    });
  };
    

  const renderContent = () =>
  {
    return useMemo(() =>
    {
      return (
        <div className="container-xxl">
          {renderHeader()}
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 flex-column">
              <div className="tab-content mt-4">
                <div className="tab-pane fade show active" id="All-list">
                  <div className="row g-3 gy-5 py-3 row-deck">
                    {renderProjectList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }, [projectList]);
  };
    
  return (
    <div className="body d-flex py-lg-3 py-md-2 project-list-container">
      {renderContent()}
      <ModalComponent ref={modalProject}>
        <ModalProject project={dataItem} onClose={onCloseModal}/>
      </ModalComponent>
      {/* <ModalComponent ref={modalUser}>
        <ModalMember/>
      </ModalComponent> */}
    </div>  
  );
};

export default ProjectList;
