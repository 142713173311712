import { notify } from './../../global/helpers';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { takeLeading, put, call } from 'redux-saga/effects';
import { loginSuccess, loginFailed, loginRequest, checkLoginRequest, logoutRequest, logoutSuccess, logoutFailed } from './slice';
import { checkLoginServer } from './actions';
import { USER_CHECK_LOGIN_LOCAL_STORAGE, USER_CHECK_LOGIN_SERVER, USER_LOGIN_SERVER, USER_LOGOUT_SERVER } from './types';
import { IAction, IResponse } from '../../app/types';
import ApiUtils from '../../global/services';
import { USER_CHECK_LOGIN, USER_LOGIN, USER_LOGOUT } from './api';
import { TOKEN_COOKIE } from '../../global/constants';
import { ROUTES } from '../../routes/types';

function* loginServerSaga(action: IAction)
{
  try
  {
    yield put(loginRequest());
    const response : IResponse = yield call(ApiUtils.request, { api: USER_LOGIN, method: 'post', data: action.payload, showLog: true });
    if (response.success)
    {
      const token = {
        authenId    : response.data.id,
        accessToken : action.payload.accessToken,
        userAgent   : action.payload.userAgent
      };
      yield localStorage.setItem(TOKEN_COOKIE, JSON.stringify(token));
      ApiUtils.updateHeaders(token);
      yield put(loginSuccess(response.data));
    } else
    {
      notify({ type: 'error', message: response.message || 'Login failed' });  
      yield put(loginFailed(response.message)); 
    }
  } catch(error) {
    yield put(loginFailed(error.message)); 
  }
}

function* checkLoginLocalStorageSaga()
{
  const token : string = yield localStorage.getItem(TOKEN_COOKIE);
  if(token && token !== ''){
    yield put(checkLoginServer(token));
  } else
  {
    yield put(loginFailed('User not login yet')); 
  }
}


function* checkLoginServerSaga(action: IAction): Generator
{
  try
  {
    yield put(checkLoginRequest());
    const response: any = yield call(ApiUtils.request, { api: USER_CHECK_LOGIN, method: 'get', headers: JSON.parse(action.payload) });
    if (response.success)
    {
      ApiUtils.updateHeaders(JSON.parse(action.payload));
      yield put(loginSuccess(response.data));
    } else
    {
      yield put(loginFailed(response.message)); 
    }
  } catch(error) {
    yield put(loginFailed(error.message)); 
  }
}

function* logoutServerSaga()
{
  try
  {
    const token : string = yield localStorage.getItem(TOKEN_COOKIE);
    yield put(logoutRequest());
    const response: IResponse = yield call(ApiUtils.request, { api: USER_LOGOUT, method: 'get', headers: JSON.parse(token) });
    
    if (response.success)
    {
      window.location.href = ROUTES.login;
      yield put(logoutSuccess());
      yield localStorage.removeItem(TOKEN_COOKIE);
    } else
    {
      yield put(logoutFailed(response.message)); 
    }
  } catch(error) {
    yield put(logoutFailed(error.message)); 
  }
}



const userSaga = [
  takeLeading(USER_LOGIN_SERVER, loginServerSaga),
  takeLeading(USER_CHECK_LOGIN_LOCAL_STORAGE, checkLoginLocalStorageSaga),
  takeLeading(USER_CHECK_LOGIN_SERVER, checkLoginServerSaga),
  takeLeading(USER_LOGOUT_SERVER, logoutServerSaga)
];

export default userSaga;