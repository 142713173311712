import React, { useEffect } from 'react';
import './index.scss';
import 'antd/dist/antd.css';
import AppRouter from './routes/AppRouter';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { checkLoginLocalStorage } from './modules/user/actions';
import Loading from './components/loading/index';

function App(): JSX.Element
{
  const dispatch = useAppDispatch();
  const { loading, isLoginChecked } = useAppSelector(state => ({
    loading        : state.user.loading,
    isLoginChecked : state.user.isLoginChecked
  }));
    
  useEffect(() =>
  {
    if (!isLoginChecked)
    {
      dispatch(checkLoginLocalStorage());
    }
  }, []);
    
  const maskedColor = loading ? { maskedColor: '#fff' } : {};
  return (
    <>
      <AppRouter />
      <Loading loading={loading} {...maskedColor} />    
    </>
  );
}

export default App;
