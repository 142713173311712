export const USER_API = {
  login: ''
};

export const USER_LOGIN_SERVER = 'USER_LOGIN_SERVER';
export const USER_CHECK_LOGIN_LOCAL_STORAGE = 'USER_CHECK_LOGIN_LOCAL_STORAGE';
export const USER_CHECK_LOGIN_SERVER = 'USER_CHECK_LOGIN_SERVER';
export const USER_LOGOUT_SERVER = 'USER_LOGOUT_SERVER';

export interface IUser {
  information: IUserInformation,
  isAuthenticated: boolean,
  errorMessage: string,
  loading: boolean,
  isLoginChecked: boolean
}

export interface IUserItem
{
  id: string,
  name: string,
  email: string,
  avatarUrl: string | null,
  role?: string,
}

export interface IUserInformation
{
  name: string,
  email: string | undefined,
  id: string,
  avatarUrl: string | undefined,
  department?: string | undefined,
  position?: string | undefined,
  numOfProjects?: number,
  numOfTasks?: number,
  numOfTodoTasks?: number,
  numOfInprogressTasks?: number,
  roleId?: string,
  role?: string,
}

export interface IUserOfList
{
  name: string,
  email: string | undefined,
  id: string,
  avatarUrl: string | undefined,
  department?: string | undefined,
  position?: string | undefined,
  numOfProjects?: number,
  roleId?: string,
  role?: string,
  createdAt?: string
  updatedAt?: string,
  status: boolean,
}

export interface ICreateUser
{
  email: string,
  name: string,
  status: boolean,
  position: string,
  department: string,
}

export interface ILoginForm
{
  accessToken : string,
  email : string,
  avatarUrl : string,
  name: string,
  userAgent: string
}